import React, { useState } from 'react';
import './addbutton.css';
import PropTypes from 'prop-types';

function AddButton(props) {
  const { actions } = props;
  const [promptVisible, setPromptVisible] = useState(false);
  return (
    <div className="add-container">
      {promptVisible && (
        <div className="add-menu-container">
          {actions.map(action => (<div key={action.title}><div className="add-button-item" role="button" tabIndex="0" onClick={() => { action.action(); setPromptVisible(false); }} onKeyPress={() => { action.action(); setPromptVisible(false); }}>{action.title}</div></div>))}
        </div>
      )}
      <div role="button" onKeyPress={() => setPromptVisible(!promptVisible)} onClick={() => setPromptVisible(!promptVisible)} tabIndex="-1" className={`add-button add-button-${promptVisible}`}><div className="add-plus" /></div>

    </div>
  );
}

export default AddButton;
AddButton.defaultProps = {
  actions: [],
};
AddButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  actions: PropTypes.array,
};
