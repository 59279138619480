// imports
import React, {
  useState, useEffect,
} from 'react';
import { Redirect } from 'react-router-dom';
import '../../css/rsvp.css';

import { EventDefaultProps, EventPropTypes } from '../Events/EventPropValidation';
import { createGroup } from '../../actions/rsvpActions';
import BackButton from '../buttons/BackButton';


function GroupDetail() {
  const [name, setName] = useState('');
  const [invitename, setInviteName] = useState('');
  const [invitephone, setInvitePhone] = useState('');
  const [supported, setSupported] = useState(false);
  const [invites, setInvites] = useState([]);
  const [redirectLink, setRedirect] = useState(null);
  async function getContacts() {
    let contacts = [];
    const prop = ['name', 'tel'];
    const opts = { multiple: true };
    if (supported) {
      contacts = await navigator.contacts.select(prop, opts);
      if (contacts) {
        setInvites(invites.push(contacts.map(t => ({
          name: t.name,
        }))));
      }
    } else {
      // eslint-disable-next-line no-alert
      alert('Not supported');
    }
    return contacts;
  }
  function addContact() {
    invites.push({ name: invitename, invitephone });
    setInvites(invites);
    setInviteName('');
    setInvitePhone('');
  }
  useEffect(() => {
    // action on update of invited

    setSupported('contacts' in navigator && 'ContactsManager' in window);
    // async function fetchData() {
    // }
    // fetchData();
  }, []);
  async function createGroupAction() {
    const response = await createGroup(name, invites);
    // eslint-disable-next-line no-console
    console.log(response);
    setRedirect(`/groups/${response.id}`);
  }
  return (
    <div className="main-container">
      {redirectLink ? <Redirect to={redirectLink} /> : ''}
      <BackButton click={() => setRedirect('/list')} />
      <div className="rsvp-title">Create New Group</div>
      <div>
        <input
          // ref={(input) => { nameInput = input; }}
          tabIndex={-1}
          onChange={e => setName(e.target.value)}
          className="rsvp-input"
          placeholder="Group Name"
          type="text"
        />
      </div>
      <div className="solid" />
      <input
          // ref={(input) => { nameInput = input; }}
        tabIndex={-1}
        onChange={e => setInviteName(e.target.value)}
        className="rsvp-input"
        placeholder="Name"
        type="text"
      />
      <input
          // ref={(input) => { nameInput = input; }}
        tabIndex={-1}
        onChange={e => setInvitePhone(e.target.value)}
        className="rsvp-input"
        placeholder="Phone"
        type="text"
      />
      <div
        role="button"
        tabIndex={-1}
        className=""
        onKeyPress={() => addContact()}
        onClick={() => addContact()}
      >
        Add Contact
      </div>
      {supported && (
        <div
          role="button"
          tabIndex={-1}
          className=""
          onKeyPress={() => getContacts()}
          onClick={() => getContacts()}
        >
          Add Contacts
        </div>
      )}
      <div className="guest-list">
        <ul>
          {invites.map(invite => (
            <li key={invite.phoneNumber}>{invite.name}</li>
          ))}
        </ul>
      </div>
      <div
        role="button"
        tabIndex={-1}
        className="respond-button respond-button-sticky respond-video-button"
        onKeyPress={() => createGroupAction()}
        onClick={() => createGroupAction()}
      >
        Create Group
      </div>
    </div>
  );
}

export default GroupDetail;
GroupDetail.defaultProps = EventDefaultProps;
GroupDetail.propTypes = EventPropTypes;
