import React, { Component } from 'react';
import Picker from 'react-mobile-picker-scroll';
import './example.scss';

class TimeScroll extends Component {
  constructor(props) {
    super(props);
    const minute = [];
    for (let i = 0; i < 60; i++) {
      minute.push(i);
    }
    this.state = {
      isPickerShow: true,
      valueGroups: {
        Hour: 0,
        Minute: 0,
        AmPm: '',
      },
      optionGroups: {
        Hour: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        Minute: minute,
        AmPm: ['Am', 'Pm'],
      },
    };
  }

  togglePicker = () => {
    // this.setState(({isPickerShow}) => ({
    //   isPickerShow: !isPickerShow
    // }));
    this.props.gotPicked();
  };


  // Update the value in response to user picking event
  handleChange = (name, value) => {
    this.setState(({ valueGroups }) => ({
      valueGroups: {
        ...valueGroups,
        [name]: value,
      },
    }));
  };

  render() {
    const { isPickerShow, optionGroups, valueGroups } = this.state;
    const maskStyle = {
      display: isPickerShow ? 'block' : 'none',
    };
    const pickerModalClass = `picker-modal${isPickerShow ? ' picker-modal-toggle' : ''}`;

    this.props.timeData(valueGroups);
    return (
      <div className="picker-modal-container">
        <div className="picker-modal-mask" style={maskStyle} onClick={this.togglePicker} />
        <div className={pickerModalClass}>
          <header>
            <a href="javascript:;" onClick={this.togglePicker}>OK</a>
          </header>
          <Picker
            optionGroups={optionGroups}
            valueGroups={valueGroups}
            onChange={this.handleChange}
          />
        </div>
      </div>
    );
  }
}

export default TimeScroll;
