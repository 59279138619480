import React, {
  useContext, Fragment, useState, useEffect,
} from 'react';
import { Redirect } from 'react-router-dom';
import ls from 'local-storage';
import { AuthContext } from '../../contexts/AuthContext';
import { getAllMyEventsFromStorage, getGroupEventByCode, getEvents } from '../../actions/rsvpActions';
import EventDetailHeader from './EventDetailHeader';
import AddButton from '../buttons/AddButton';

// TODO: Clean up useEffect log out issues

function EventListProvider(props) {
  const authContext = useContext(AuthContext);
  const { dispatch } = authContext;
  const { token, me } = authContext.auth;
  const [error, setError] = useState('');
  const [name, setName] = useState('');
  const [events, setEvents] = useState([]);
  const [redirectLink, setRedirect] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        // if user logged in, get their events

        // if not logged in, get events from storage

        // see if this user has access to the event, if they do then send them to the details page
        // if this user is not logged in, see if they have a phoneNumber saved in storgae
        if (!token) {
          setName(await ls.get('name'));
          // eslint-disable-next-line no-console
          const eventList = await getAllMyEventsFromStorage();
          const myEvents = await Promise.all(
            eventList.map(event => getGroupEventByCode(event.eventCode)),
          );

          setEvents(myEvents);
          // eslint-disable-next-line no-console
          console.log('My Events', { events, myEvents });
        } else {
          setName(me.name);
          setEvents(await getEvents(token));
        }
      } catch (err) {
        setError(err.message || 'Error!');
      }
    }
    fetchData();
  }, [token]);
  function navigateToEvent(event) {
    // navigate to the event
    if (event.eventCode) {
      setRedirect(`/${event.eventCode}`);
    } else {
      // group invite
      setRedirect(`/groups/${event.groupId}`);
    }
  }
  function createGroup() {
    setRedirect('/groups/create');
  }
  const actions = [];
  actions.push({ title: token ? 'Logout' : 'Log In/Register', action: () => { dispatch({ type: 'CLEAR_AUTH' }); window.location = '/'; } });
  actions.push({ title: token ? 'Create An Invite' : 'Log In/Register', action: () => { window.location = '/create'; } });
  return (
    <Fragment>
      {error}
      {redirectLink ? <Redirect to={redirectLink} /> : ''}

      <div className="event-list">

        <div className="fake-padding" />
        {events.filter(evt => evt.eventType !== 'GROUP_JOIN').map(event => (
          <EventDetailHeader
            key={event.id}
            event={event}
            confirmedCount=""
            maybeCount=""
            showFlip={false}
            flipPressed={() => navigateToEvent(event)}
            click={() => navigateToEvent(event)}
            showDirectionPanel={false}
            showResponse="true"
          />
        ))}
      </div>
      <div className="image-container" />
      <div className="rsvp-image" />
      <div className="image-container-bottom" />


      <AddButton actions={actions} />
    </Fragment>
  );
}

export default EventListProvider;
