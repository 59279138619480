/* eslint-disable no-restricted-globals */
import React, { Fragment } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Helmet } from 'react-helmet';
// import OldEventDetailProvider from './components/Events/OldEventDetailProvider';
import EventDetailProvider from './components/Events/EventDetailProvider';
import SignIn from './components/auth/sign-in';
import AuthContextProvider from './contexts/AuthContext';
import PromptBanner from './components/prompt/PromptBanner';
import EventAnon from './components/Events/EventAnon';
import EventListProvider from './components/Events/EventListProvider';
import GroupDetailProvider from './components/Groups/GroupDetailProvider';
import withTracker from './actions/withTracker';
import GroupCreateProvider from './components/Groups/GroupCreateProvider';
import CreateInvite from './components/rsvps/CreateInvite/CreateInvite';
import InvitesMap from './components/rsvps/CreateInvite/InvitesMap';

function App() {
  const history = createBrowserHistory();

  const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];
  if (path) {
    history.replace(path);
  }
  return (
    <AuthContextProvider>
      <Router>
        <Fragment>
          <main className="app-main">
            <Helmet>
              <title>Invites App</title>
              <meta property="og:title" content="Invites App" />
              <meta property="og:description" content="Invite Friends To Events" />
              <meta property="og:image" content={require('./images/background-web.svg')} />
              <meta property="og:url" content={`${location.protocol}//${location.host}`} />
              <meta property="og:type" content="website" />
              <meta property="og:locale" content="en_US" />
            </Helmet>
            <Switch>
              {/* <Route
                exact
                path="/"
                component={() => {
                  window.location.href = 'https://www.invites.ac';
                  return null;
                }}
              /> */}
              <Route exact path="/invitesMap" component={InvitesMap} />
              <Route exact path="/list" component={EventListProvider} />
              <Route exact path="/create" component={CreateInvite} />
              <Route exact path="/groups/:groupId/events/:eventId/invites/:inviteCode" component={EventAnon} />
              <Route exact path="/groups/create" component={GroupCreateProvider} />
              <Route exact path="/groups/:groupId" component={GroupDetailProvider} />
              <Route exact path="/:eventCode" component={withTracker(EventDetailProvider)} />
              {/* <Route exact path="/:eventCode/details"
              component={EventDetailContainer} /> */}

              {/* Not sure if /event route was being used */}
              {/* <Route exact path="/event" component={OldEventDetailProvider} /> */}
              <Route exact path="/banner" component={PromptBanner} />
              <Route exact path="/" component={props => <SignIn {...props} />} />
            </Switch>
          </main>
        </Fragment>
      </Router>
    </AuthContextProvider>
  );
}

export default App;
