import React from 'react';
import './menubutton.css';
import PropTypes from 'prop-types';

function MenuButton(props) {
  const { click } = props;
  return (
    <div className="menu-button" tabIndex="0" role="button" onClick={() => click()} onKeyPress={() => click()}>
      <div className="menu-line" />
      <div className="menu-line-small" />
      <div className="menu-line" />
    </div>
  );
}

export default MenuButton;
MenuButton.defaultProps = {
  click: null,
};
MenuButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  click: PropTypes.func,
};
