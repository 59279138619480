import React, { useState, Fragment } from 'react';
import GoogleMapReact from 'google-map-react';
import GooglePlacesAutocomplete, { geocodeByAddress, geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import Marker from './Marker';
import 'react-google-places-autocomplete/dist/index.min.css';
// import loader from '../assets/loader.svg';


// const AnyReactComponent = ({text}) => <div>{text}</div>;

const SimpleMap = (props) => {
  const [center, setCenter] = useState({ lat: 38.9029, lng: -77.0390 });
  const [zoom, setZoom] = useState(11);
  const [searchValue, setSearchValue] = useState();
  const [placeName, setPlaceName] = useState();
  const [placeId, setPlaceId] = useState();
  const newSelect = (description) => {
    geocodeByAddress(description)
      .then(results => getLatLng(results[0]))
      .then(({ lat, lng }) => setCenter({
        lat,
        lng,
      }));
  };
  const setInputValue = (e) => {
    setSearchValue(e.target.value);
  };
  const clearInput = () => {
    setSearchValue('');
    setPlaceName(null);
    props.mapData(null);
  };
  const handleApiLoaded = (map, maps) => {
    const marker = new maps.Marker({
      position: null,
      animation: maps.Animation.DROP,
      draggable: true,
      map,
    });
    const autocomplete = new maps.places.Autocomplete(
      document.getElementById('autocomplete'),
      {
        // types: ['(cities)'],
        componentRestrictions: { country: 'us' },
      },

    );
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      props.mapData(place);
      setPlaceName(place.name);
      if (place.geometry) {
        const { location } = place.geometry;
        map.panTo(location);
        map.setZoom(10);
        marker.setPosition(location);
      } else {
        const autocom = document.getElementById('autocomplete');
        autocom.placeholder = 'Enter a city';
      }
    });
  };
  return (
    <Fragment>
      <div className={`${true} venmo-entry location-search link-add`} style={{ marginBottom: '.5rem' }}>
        <FontAwesomeIcon
          style={{ paddingLeft: '11px' }}
          icon={faSearch}
          color="#9ea3d6"
        />
        <input
          style={{ color: 'black' }}
          id="autocomplete"
          onChange={e => setInputValue(e)}
          value={searchValue === '' ? searchValue : placeName || searchValue}
        />

        <FontAwesomeIcon
          style={{ paddingRight: '11px' }}
          icon={faTimesCircle}
          onClick={clearInput}
          color="#9ea3d6"
        />
      </div>
      {/* <GooglePlacesAutocomplete
        style={{ color: 'black' }}
        onSelect={( {  description } ) => newSelect( description )}
      /> */}

      {/* <div id="locationField">
            <input id="autocomplete" placeholder="Enter a city" type="text" />
          </div> */}
      <div style={
        {
          height: '20vh',
          width: '90vw',
          position: 'relative',
          bottom: '0%',
          right: '0%',
        }
      }
      >
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyAelmk7VljgQLaRl3I3qHrRVJCy8BLzffM' }}
          defaultCenter={center}
          defaultZoom={zoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        >
          {/* <Marker
            lat={center.lat}
            lng={center.lng}
            name="invites-marker"
            color="#3c3c67"
          /> */}
        </GoogleMapReact>
      </div>
    </Fragment>
  );
};

export default SimpleMap;
