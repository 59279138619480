const env = process.env.REACT_APP_ENV;
const environments = {
  dev: 'https://api.dev.invites.ac',
  prod: 'https://api.invites.ac',
};
const configs = {
  dev: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_ZHE7BdJyS',
    userPoolWebClientId: '4v3jmrsne3c5u4hc1no13m7ccp',
  },
  prod: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_IMAfLRVzX',
    userPoolWebClientId: '5siccl57odne1phjbjmn851lpp',
  },
};
export const apiUrl = environments[env];
export const config = configs[env];
