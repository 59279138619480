import React from 'react';
import './PromptBannerAssets/promptbanner.css';
import PropTypes from 'prop-types';

function PromptBanner(props) {
  const { cancel, promptVisible, bannerClicked } = props;
  return (
    <div className={`prompt-banner-${promptVisible}-${bannerClicked}`}>
      <h2 className="prompt-banner--title">Install the invites app!</h2>
      <h4 className="prompt-banner--desc">It&apos;s faster and better than the web!</h4>
      <button type="button" className="prompt-banner-button">
        Install Invites App!
      </button>
      <span
        className="prompt-banner-cancel"
        tabIndex={-1}
        role="button"
        onKeyDown={cancel}
        onClick={cancel}
      >
        <img
          alt="cancel"
          src={`${process.env.PUBLIC_URL}/assets/closeMenuIco/closeMenuIco@3x.png`}
        />
      </span>
    </div>
  );
}

export default PromptBanner;
PromptBanner.defaultProps = {
  cancel: null,
  bannerClicked: null,
  promptVisible: false,
};
PromptBanner.propTypes = {
  cancel: PropTypes.func,
  bannerClicked: PropTypes.func,
  promptVisible: PropTypes.bool,
};
