// External Imports

import React, {
  Fragment, useState, useContext, useEffect,
} from 'react';
import DatePicker from 'react-datepicker';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faTimes,
  faSearch,
  faChevronDown,
  faEdit,
  faPlusCircle,
  faMinusCircle,
} from '@fortawesome/free-solid-svg-icons';
import { set } from 'local-storage';
import { AuthContext } from '../../../contexts/AuthContext';
// Action Imports
import { getGroups } from '../../../actions/rsvpActions';
import { createGroup, createGroupEvent } from '../../../actions/rsvpActions';
// Component Imports
import InvitesMap from './InvitesMap';
import TimeScroll from './TimeScroll';
import { groupTypeArray, dummyGroup } from './groupTypes';
// css Imports
import './createInvite.css';
import '../../../css/rsvp.css';
import 'react-datepicker/dist/react-datepicker.css';
import '../../../css/index.css';

const CreateInvite = () => {
  // context declarations
  const authContext = useContext( AuthContext );
  const { token, me } = authContext.auth;
  // state declarations
  const [redirectLink] = useState( null );
  const [error, setError] = useState( '' );
  const [groupSelectPhase, setGroupSelectPhase] = useState( true );
  const [creatingEventPhase, setCreatingEventPhase] = useState( false );
  const [creatingEventForm, setCreatingEventForm] = useState( false );
  const [creatingEventDate, setCreatingEventDate] = useState( false );
  const [finished, setFinished] = useState( false );
  const [tempTime, setTempTime] = useState();
  const [eventTime, setEventTime] = useState();
  const [eventURL, setEventURL] = useState();
  const [eventLocationName, setEventLocationName] = useState();
  const [eventStartDate, setEventStartDate] = useState();
  const [payModalOn, setPayModalOn] = useState( false );
  const [mapPhase, setMapPhase] = useState( false );
  const [linkPhase, setLinkPhase] = useState( false );
  const [isPickerShow, setIsPickerShow] = useState( false );
  const [memberModalOn, setMemberModalOn] = useState( false );
  const [selectingGroup, setSelectingGroup] = useState( false );
  const [selectingGroupType, setSelectingGroupTypes] = useState( false );
  const [visibleForm, setVisibleForm] = useState( false );
  const [groupIdForCreate, setGroupIdForCreate] = useState();
  const [newGroupName, setNewGroupName] = useState();
  const [mapNotFinished, setMapNotFinished] = useState( true );
  const [selectedGroup, setSelectedGroup] = useState();
  const [selectedGroupType, setSelectedGroupType] = useState();
  const [eventName, setEventName] = useState();
  const [eventLat, setEventLat] = useState();
  const [eventLng, setEventLng] = useState();
  const [eventDescription, setEventDescription] = useState();
  const [linkSelected, setLinkSelected] = useState( true );
  const [venmoUserName, setVenmoUserName] = useState();
  const [locationSelect, setLocationSelect] = useState( false );
  const [cost, setCost] = useState( 0 );
  const [mapWasClosed, setMapWasClosed] = useState( false );
  const [memberMin, setMemberMin] = useState( 0 );
  const [memberMax, setMemberMax] = useState( 0 );
  const [groups, setGroups] = useState( [] );

  // actions
  const actions = [];
  actions.push( {
    title: token ? 'Logout' : 'Log In/Register',
    action: () => {
      window.location = '/';
    },
  } );

  // on load
  useEffect( () => {
    async function fetchData() {
      try {
        const fetchedGroups = await getGroups();
        const groupsAvailable = fetchedGroups.filter( group => group.organizerId === me.id );
        groupsAvailable.push( dummyGroup );
        setGroups( groupsAvailable );
        setSelectedGroup( groupsAvailable[0] );
        setGroupIdForCreate( groupsAvailable[0].id );
        setSelectedGroupType( groupsAvailable[0].groupType );
      } catch ( err ) {
        setError( err.message || 'Error!' );
      }
    }
    fetchData();
  }, [] );
  // NAV
  const backToList = () => {
    window.location = '/';
  };

  const backHome = () => {
    window.location = '/';
  };

  const backToGroups = () => {
    setCreatingEventPhase( false );
    setGroupSelectPhase( true );
  };
  const backToOnToEvents = () => {
    setCreatingEventPhase( true );
    setCreatingEventForm( false );
  };
  const backToCreatingEventForm = () => {
    setCreatingEventForm( true );
    setCreatingEventDate( false );
  };
  const backToCreatingEventDate = () => {
    setCreatingEventDate( true );
    setFinished( false );
  };


  // setters
  const changeGroupName = ( event ) => {
    setNewGroupName( event.target.value );
  };
  const showGroups = () => {
    setSelectingGroup( true );
  };
  const showGroupTypes = () => {
    setSelectingGroupTypes( true );
  };
  // event form actions
  const changeEventURL = ( event ) => {
    setEventURL( event.target.value );
  };
  const changeEventLocationName = ( e ) => {
    setEventLocationName( e.target.value );
  };
  const changeEventName = ( event ) => {
    setEventName( event.target.value );
  };
  const changeEventDescription = ( event ) => {
    setEventDescription( event.target.value );
  };

  const scheduleEventSelected = () => {
    setGroupSelectPhase( false );
    setCreatingEventPhase( false );
    setCreatingEventForm( true );
  };
  const groupSelected = ( group, e ) => {
    setSelectedGroup( group );
    setGroupIdForCreate( group.id );
    setSelectingGroup( false );
    if ( group.id === '0' ) {
      setVisibleForm( true );
    } else {
      setVisibleForm( false );
    }
  };
  const groupTypeSelected = ( type, e ) => {
    setSelectedGroupType( type );
    setSelectingGroupTypes( false );
  };
  const dateClicked = ( day, e ) => {
    setEventStartDate( moment().add( day.number, 'day' ).toISOString() );
    setCreatingEventDate( false );
    setFinished( true );
  };

  const changeDate = ( date ) => {
    setEventStartDate( moment( date ).toISOString() );
    setCreatingEventDate( false );
    setFinished( true );
  };
  
  const costUp = () => {
    setCost( cost + 1 );
  };
  const costDown = () => {
    if ( cost > 0 ) {
      setCost( cost - 1 );
    }
  };
  const clearCost = () => {
    setCost( 0 );
  };
  const memberMinDown = () => {
    if ( memberMin > 0 ) {
      setMemberMin( memberMin - 1 );
    }
  };
  const venmoUserNameChange = ( e ) => {
    setVenmoUserName( e.target.value );
  };
  const memberMinUp = () => {
    if ( memberMax > memberMin ) {
      setMemberMin( memberMin + 1 );
    }
  };
  const memberMaxDown = () => {
    if ( memberMax > 0 && memberMax > memberMin ) {
      setMemberMax( memberMax - 1 );
    }
  };
  const memberMaxUp = () => {
    setMemberMax( memberMax + 1 );
  };
  const updateMemberLimits = () => {
    setMemberModalOn( false );
  };
  const updatePayment = () => {
    setPayModalOn( false );
  };
  const clearMemberLimits = () => {
    setMemberMin( 0 );
    setMemberMax( 0 );
  };
  const getTime = ( timeData ) => {
    setTempTime( timeData );
  };
  const getMapData = ( mapData ) => {
    if ( mapData ) {
      setEventLocationName( mapData.name );
      setEventURL( mapData.url )
      setEventLat( mapData.geometry.location.lat() );
      setEventLng( mapData.geometry.location.lng() );
    } else {
      setEventLng( null );
      setEventLat( null );
      setEventLocationName( '' );
    }
  };
  const gotPicked = () => {
    setEventTime( tempTime );
    setIsPickerShow( false );
  };
  const backToLocation = () => {
    setLinkPhase( false );
    setMapNotFinished( true );
    setMapPhase( true );
  };
  const locationInactive = () => {
    setLocationSelect( false );
  };
  const clearEventLocations = () => {
    setEventLat(undefined);
    setEventLng(undefined);
    setEventLocationName(undefined);
    setEventURL(undefined);
    setMapNotFinished(true);
    setMapPhase( false );
    setLinkPhase( false );
    setMapWasClosed( true );
  };
  const updateLocations = () => {
    setMapPhase( false );
    setLinkPhase( true );
    setMapNotFinished( false );
  };
  const updateAllLocations = () => {
    setMapPhase( false );
    setLinkPhase( false );
  };
  const emojiClicked = ( emoji, e ) => {
    switch ( emoji.action ) {
      case 'pay':
        setPayModalOn( true );
        break;
      case 'memberLimit':
        setMemberModalOn( true );
        break;
      case 'time':
        setIsPickerShow( true );
        break;
      case 'location':
        setLocationSelect( true );
        if ( mapNotFinished ) {
          setMapPhase( true );
        } else {
          setLinkPhase( true );
        }
      default:
        break;
    }
  };

  // Text formatting
  const memberLimitsFormat = () => {
    if(memberMax) {
      return (
        memberMin ? `👩‍👦‍👦 Member Limites betweed ${memberMin}-${memberMax}`
        :  `👩‍👦‍👦 Member Limites betweed 0-${memberMax}`
      );
    }
    return '👩‍👦‍👦 No member limits'
  }
  const paymentText = () => {
    if(cost){
      return `💵 Event is priced at $${cost}`
    }
    return '💵 No payment is required';
  }
  const locationFormat = () =>{
    if(eventLocationName){
      return `🌎 Event held at ${eventLocationName.slice(0,15)} ${eventLocationName.length > 15 ? '...' : ''}`
    }
    return '🌎 No action or URL needed'
  }
  const timeFormat = () => {
    if ( eventTime ) {
      let hour = eventTime.Hour.toString();
      let minute = eventTime.Minute.toString();
      if ( eventTime.Minute < 10 ) {
        minute = '0'+minute;
      }
      return '🕰️ Event is scheduled for ' + hour + ':' +minute + ' ' + eventTime.AmPm;
    }
    else return '🕰️ Time is unimportant'
  }
  // jsx creation functions
  const groupsList = groups.map( group => (
    <div onClick={groupSelected.bind( this, group )} className="group-list" key={group.id}>
      {group.name}
    </div>
  ) );
  const groupTypes = groupTypeArray.map( type => (
    <div onClick={groupTypeSelected.bind( this, type )} className="group-list">
      {type}
    </div>
  ) );
  const dateArray = [{ name: 'today', number: 0 }, { name: 'Tomorrow', number: 1 }];
  for ( let i = 2; i < 7; i++ ) {
    const nextDay = moment().add( i, 'day' );
    const dateObject = {
      name: nextDay.format( 'dddd, Do' ),
      number: i,
    };
    dateArray.push( dateObject );
  }
  const detailsArray = [
    {
      text: timeFormat(),
      action: 'time',
    },
    {
      text: paymentText(),
      action: 'pay',
    },
    {
      text: locationFormat(),
      action: 'location',
    },
    {
      text: memberLimitsFormat(),
      action: 'memberLimit',
    },
  ];
  const detailsList = detailsArray.map( ( data, index ) => (
    <div className="details-select" key={index + 101}>
      <div className="rsvp-details-cell" role="button">
        {data.text}
        <FontAwesomeIcon
          onClick={emojiClicked.bind( this, data )}
          icon={faEdit}
          style={{ float: 'right' }}
          color="#9ea3d6"
        />
      </div>
    </div>
  ) );
  const dateList = dateArray.map( ( data, index ) => (
    <div key={data.number}>
      <div onClick={dateClicked.bind( this, data )} className="rsvp-user-cell" role="button">
        <div className="rsvp-user-cell--number">{index + 1}</div>
        <div className="rsvp-user-name rsvp-user-cell-col">{data.name}</div>
        <div className="rsvp-user-response rsvp-user-cell-col">
          <img src={`${process.env.PUBLIC_URL}/assets/arrow/arrow.png`} />
        </div>
      </div>
      <div className="solid" />
    </div>
  ) );


  // submits

  const makeid = () => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for ( let i = 0; i < 6; i++ ) {
      result += characters.charAt( Math.floor( Math.random() * charactersLength ) );
    }
    return result;
  };
  const handleSubmitGroup = async ( e ) => {
    e.preventDefault();
    if ( selectedGroup ) {
      if ( groupIdForCreate === '0' ) {
        const data = await createGroup( newGroupName, selectedGroupType );
        setGroupIdForCreate( data.id );
      }
    }
    setGroupSelectPhase( false );
    setCreatingEventPhase( true );
  };
  const handleSubmitEventName = ( e ) => {
    e.preventDefault();

    if ( !!eventName && eventDescription ) {
      setCreatingEventForm( false );
      setCreatingEventDate( true );
    } else return;
  };
  const submittingEvent = ( e ) => {
    e.preventDefault();
    const eventCode = makeid();
    // TODO get these endpoint features

    // name: string;
    // eventType: GroupEventType;
    // eventDateTime: string;
    // eventEndDateTime?: string;
    // allDay: boolean;
    // location?: string;
    // locationUrl?: string;
    // eventCode: string;
    // notes?: string;
    // paymentAmount?: number;
    // paymentDueDateTime?: string;
    // lat?: number;
    // lon?: number;
    // creatorId: string;
    // eventUrl?: string;
    // venmoUsername?: string;
    // eventMediaUrl?: string;
    // linkName?: string;
    // linkUrl?: string;
    const event = {
      name: eventName,
      eventType: 'LOCATION_WITH_PAYMENT',
      eventDateTime: eventStartDate,
      description: eventName,
      allDay: true,
      eventUrl: eventURL || null,
      eventCode,
      notes: eventDescription,
      paymentAmount: cost || 0,
      creatorId: me.id,
      eventTime: eventTime || '',
      location: eventLocationName || '',
      // locationUrl: eventURL,
      lat: eventLat || null,
      lng: eventLng || null,
      venmoUserName: venmoUserName || null,

    };
    createGroupEvent(groupIdForCreate, event);
    setTimeout(() => {
      window.location = `/${eventCode}`;
    }, 1500);
    // window.location = '/';
  };
  return (
    <Fragment>
      {error}
      {redirectLink ? <Redirect to={redirectLink} /> : ''}
      <div className={`${groupSelectPhase}`}>
        <div className="create-event--group-banner">
          <FontAwesomeIcon onClick={backToList} icon={faArrowLeft} color="#9ea3d6" />
          <FontAwesomeIcon onClick={backHome} icon={faTimes} style={{ float: 'right' }} color="#9ea3d6" />
          <h1>Select Group</h1>
          <h3>Create a New Group or Add an invite for an Existing Group</h3>
        </div>
        <div className="create-event--group-select">
          <form className="create-form" onSubmit={handleSubmitGroup}>
            <div onClick={showGroups} className="mobile-select">
              <div className="select-label">
                {selectedGroup ? selectedGroup.name : ''}
                {/* <DownOutlined style={{ display: 'inline', color: 'white'}}/> */}
              </div>
              <FontAwesomeIcon color="#9ea3d6" icon={faChevronDown} />
            </div>
            <div className={`attending-modal-${selectingGroup}`}>
              <h2>Choose Group</h2>
              {groupsList}
            </div>
            <div className="inputing-group" id={`visible-${visibleForm}`}>
              <input placeholder="group name" type="text" onChange={changeGroupName} />
              <div onClick={showGroupTypes} className="mobile-select">
                <div className="select-label">
                  {selectedGroupType || 'Select Group Type'}
                </div>
                <FontAwesomeIcon color="#9ea3d6" icon={faChevronDown} />
              </div>
            </div>
            <div className={`attending-modal-${selectingGroupType}`}>
              <h2>Group Type</h2>
              {groupTypes}
            </div>
            <button className="respond-button " type="submit">
              Next
            </button>
          </form>
        </div>
      </div>
      <div className={`${creatingEventPhase}`}>
        <div className="create-event--group-banner">
          <FontAwesomeIcon onClick={backToGroups} icon={faArrowLeft} color="#9ea3d6" />
          <FontAwesomeIcon onClick={backHome} icon={faTimes} style={{ float: 'right' }} color="#9ea3d6" />
          <h1>Create Invite</h1>
          <h3>
            Either schedule and event now or if you prefer, invite members to first opt-in to the group.
          </h3>
        </div>
        <div className="create-invite--options">
          <div className="schedule-event">
            <div>
              <h2>Schedule an Event</h2>
              <h4>Create an invite for an upcoming event.</h4>
            </div>
            <div>
              <img
                onClick={scheduleEventSelected}
                alt="select-arrow"
                src={`${process.env.PUBLIC_URL}/assets/arrow/arrow.png`}
              />
            </div>
            <hr />
          </div>
          <div className="confirm-members">
            <div>
              <h2>Confirm Membership</h2>
              <h4>Invite people to opt-in for future events.</h4>
            </div>
            <div>
              <img alt="select-arrow" src={`${process.env.PUBLIC_URL}/assets/arrow/arrow.png`} />
            </div>
            <div />
          </div>
        </div>
      </div>
      <div className={`${creatingEventForm}`}>
        <div className="create-event--group-banner">
          <FontAwesomeIcon onClick={backToOnToEvents} icon={faArrowLeft} color="#9ea3d6" />
          <FontAwesomeIcon onClick={backHome} icon={faTimes} style={{ float: 'right' }} color="#9ea3d6" />
          <h1>Create Event</h1>
          <h3>
            You're about to create your first event.
            {' '}
            <p />
            This is gonna be fun.
          </h3>
        </div>
        <div className="create-event--group-select event-name-form">
          <form className="create-form" onSubmit={handleSubmitEventName}>
            <div id="visible-true">
              <input type="text" onChange={changeEventName} />
              <textarea style={{ height: '130px' }} type="text" onChange={changeEventDescription} />
            </div>
            <button className="respond-button " type="submit">
              Next
            </button>
          </form>
        </div>
      </div>
      <div className={`${creatingEventDate}`}>
        <div className="create-event--group-banner">
          <FontAwesomeIcon onClick={backToCreatingEventForm} icon={faArrowLeft} color="#9ea3d6" />
          <FontAwesomeIcon onClick={backHome} icon={faTimes} style={{ float: 'right' }} color="#9ea3d6" />
          <h1>Date</h1>
          <h3>What date does the event happen?</h3>
        </div>
        <DatePicker
          showPopperArrow={false}
          placeholderText="Click to select a date"
          onChange={date => changeDate( date )}
          withPortal
        />
        <div className="rsvp-user-list">{dateList}</div>
      </div>
      <div className={`${finished}`}>
        <div className="create-event--group-banner">
          <FontAwesomeIcon onClick={backToCreatingEventDate} icon={faArrowLeft} color="#9ea3d6" />
          <FontAwesomeIcon onClick={backHome} icon={faTimes} style={{ float: 'right' }} color="#9ea3d6" />
          <h1>Event details</h1>
          <h3>Set these now or skip it.</h3>
        </div>
        <div className="details-select-list">
          {detailsList}
          {isPickerShow && <TimeScroll gotPicked={gotPicked} timeData={getTime} />}
          <div>
            <div className={`${payModalOn}`}>
              <form className="attending-modal-true pop-up">
                <div className="pay-required">Payment Required</div>
                <div className="money-plus-minus">
                  <FontAwesomeIcon
                    onClick={costDown}
                    size="2x"
                    color="#8b63e6"
                    icon={faMinusCircle}
                  />
                  <div>
                    {`$${cost}`}
                    {' '}
                  </div>
                  <FontAwesomeIcon
                    onClick={costUp}
                    size="2x"
                    color="#8b63e6"
                    icon={faPlusCircle}
                  />
                </div>
                <div className="venmo-entry">
                  <FontAwesomeIcon
                    style={{ paddingLeft: '11px' }}
                    icon={faSearch}
                    color="#9ea3d6"
                  />
                  {' '}
                  <input onChange={venmoUserNameChange} placeholder="Enter Venmo username..." />
                </div>
                <div onClick={updatePayment} className="respond-button--pay ">
                  {cost === 0 ? 'Close' : 'Update Payment'}
                </div>
                <div onClick={clearCost} className="respond-button--clear">
                  Clear Payment
                </div>
              </form>
            </div>
            <div className={`${memberModalOn}`}>
              <form className="attending-modal-true pop-up member-limits">
                <div className="pay-required">Member Limits</div>

                <div className="member-plus-minus">
                  <div><label className="custom-label">Minimum:</label></div>
                  <div className="money-plus-minus">
                    <FontAwesomeIcon
                      onClick={memberMinDown}
                      size="2x"
                      color="#8b63e6"
                      icon={faMinusCircle}
                    />
                    <div>
                      {`${memberMin}`}
                      {' '}
                    </div>
                    <FontAwesomeIcon
                      onClick={memberMinUp}
                      size="2x"
                      color="#8b63e6"
                      icon={faPlusCircle}
                    />
                  </div>
                </div>

                <div className="member-plus-minus">
                  <div><label className="custom-label">Maximum:</label></div>
                  <div className="money-plus-minus">
                    <FontAwesomeIcon
                      onClick={memberMaxDown}
                      size="2x"
                      color="#8b63e6"
                      icon={faMinusCircle}
                    />
                    <div>
                      {`${memberMax}`}
                      {' '}
                    </div>
                    <FontAwesomeIcon
                      onClick={memberMaxUp}
                      size="2x"
                      color="#8b63e6"
                      icon={faPlusCircle}
                    />
                  </div>
                </div>
                <div onClick={updateMemberLimits} className="respond-button--pay ">
                  {memberMin === 0 && memberMax === 0 ? 'Close' : 'Update Member Limits'}
                </div>
                <div onClick={clearMemberLimits} className="respond-button--clear">
                  Clear Member Limits
                </div>
              </form>
            </div>
            {/* <FontAwesomeIcon
										style={{ paddingLeft: '11px' }}
										icon={faSearch}
										color={'#9ea3d6'}
									/> */}
            <div className={`${locationSelect}`}>
              <form className={`${mapPhase && mapNotFinished} attending-modal-true pop-up`}>
                <div onClick={locationInactive} className="pay-required">Event Location</div>

                <div style={{ maxWidth: '100vw' }}><InvitesMap wasClosed={mapWasClosed} mapData={getMapData} /></div>
                <div onClick={updateLocations} className="respond-button--pay ">
                  {eventLocationName ? 'Select & Continue' : 'Skip Map Location'}
                </div>
                <div onClick={clearEventLocations} className="respond-button--clear">
                  Close and Return
                </div>
              </form>
              <form className={`${linkPhase} attending-modal-true pop-up`}>
                <div className="pay-required location-back">
                  <FontAwesomeIcon style={{ position: 'absolute', left: '26px', top: '42px' }} onClick={backToLocation} icon={faArrowLeft} color="#9ea3d6" />
                  Location Details
                </div>
                <div className={`${linkSelected} venmo-entry link-add`}>
                  <input onChange={changeEventLocationName} placeholder="Name your location!" value={eventLocationName} />
                </div>
                <div className={`${linkSelected} venmo-entry link-add`}>
                  <input onChange={changeEventURL} placeholder=" Add URL i.e Zoom or Google Meetup..." value={eventURL} />
                  <img style={{ paddingRight: '11px' }} onClick={backToLocation} alt="chat-bubble" src={`${process.env.PUBLIC_URL}/assets/group-6_2020-04-30/group-6.png`} />
                </div>
                <div onClick={updateAllLocations} className="respond-button--pay ">
                  Update Event
                </div>
                <div onClick={clearEventLocations} className="respond-button--clear">
                  Clear Event
                </div>
              </form>
            </div>

          </div>
          <button onClick={submittingEvent} className="respond-button final-response" type="submit">
            Finish
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default CreateInvite;
