import ls from 'local-storage';
import axios from '../config/axios';
import { apiUrl } from '../config/environments';
import { tokenConfig, getToken } from './authActions';

async function addEventToLs(eventId, eventCode, authCode, responseStatus) {
  let events = await ls.get('events') || [];
  if (events.length) {
    events = events.filter(t => t.eventId !== eventId);
  }
  events.push({
    eventId,
    eventCode,
    authCode,
    responseStatus,
  });
  await ls.set('events', events);
}
export const getEvents = async () => {
  const token = await getToken();
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiUrl}/me/events`, tokenConfig(token))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getGroups = async () => {
  const token = await getToken();
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiUrl}/me/groups`, tokenConfig(token))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getGroup = async (groupId) => {
  const token = await getToken();
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiUrl}/me/groups/${groupId}`, tokenConfig(token))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const createGroupEvent = async (groupId, event) => {
  const token = await getToken();
  return new Promise((resolve, reject) => {
    axios.post(`${apiUrl}/me/groups/${groupId}/events`, event, tokenConfig(token))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const createGroup = async (groupName, groupType, invites) => {
  const token = await getToken();
  return new Promise((resolve, reject) => {
    const newGroup = {
      name: groupName,
      groupType,
      events: [
        {
          name: null,
          eventType: 'GROUP_JOIN',
          allDay: true,
          notes: `Do you want to join ${groupName}?`,
        },
      ],
      invitees: invites,
    };

    axios
      .post(`${apiUrl}/me/groups`, newGroup, tokenConfig(token))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getGroupEvents = async (groupId) => {
  const token = await getToken();
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiUrl}/me/groups/${groupId}/events/`, tokenConfig(token))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getEventInvitees = async (groupId, eventId) => {
  const token = await getToken();
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiUrl}/me/groups/${groupId}/events/${eventId}/invites`, tokenConfig(token))
      .then((res) => {
        res.data.forEach((entry) => {
          if (!entry.invitee.name) {
            // eslint-disable-next-line no-param-reassign
            entry.invitee.name = 'John/Jane Doe';
          }
        });
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getEventInviteesAnon = (code, groupId,
  eventId) => new Promise((resolve, reject) => {
  // make sure to url encode the phone number
  axios
    .get(`${apiUrl}/groups/${groupId}/events/${eventId}/invites?code=${code}`)
    .then((res) => {
      resolve(res.data);
    })
    .catch((err) => {
      reject(err);
    });
});
export const getGroupEvent = (groupId, eventId) => new Promise((resolve, reject) => {
  axios
    .get(`${apiUrl}/groups/${groupId}/events/${eventId}`)
    .then((res) => {
      resolve(res.data);
    })
    .catch((err) => {
      reject(err);
    });
});
export const getGroupEventByCode = eventCode => new Promise((resolve, reject) => {
  axios
    .get(`${apiUrl}/events/${eventCode}`)
    .then((res) => {
      resolve(res.data);
    })
    .catch((err) => {
      reject(err);
    });
});
// {{baseUrl}}/me/groups/{{groupId}}/events/{{eventId}}/invites/{{userId}}/payment-status
export const respondedAsPaid = async (groupId, eventId,
  userId, paymentStatus) => {
  const token = await getToken();
  return new Promise((resolve, reject) => {
    axios
      .patch(
        `${apiUrl}/me/groups/${groupId}/events/${eventId}/invites/${userId}/payment-status`,
        { paymentStatus },
        tokenConfig(token),
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const respondToAnonEvent = (code, responseStatus,
  eventId, eventCode) => new Promise((resolve, reject) => {
  axios
    .patch(
      `${apiUrl}/invites/${code}/response`,
      { responseStatus },
    )
    .then((res) => {
      // save the returned token to be used in subsequent calls
      addEventToLs(eventId, eventCode, code, responseStatus);
      resolve(res.data);
    })
    .catch((err) => {
      reject(err);
    });
});
export const respondToEvent = async (groupId, eventId,
  userId, responseStatus, code, eventCode) => {
  const token = await getToken(true);
  return new Promise((resolve, reject) => {
    if (!token) {
      return respondToAnonEvent(code, responseStatus, eventId, eventCode);
    }
    return axios
      .patch(
        `${apiUrl}/me/groups/${groupId}/events/${eventId}/invites/${userId}/response`,
        { responseStatus },
        tokenConfig(token),
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export async function searchLsForEventAuthCode(eventId) {
  const events = await ls.get('events') || [];
  const event = events.find(element => element.eventId === eventId);
  return event ? event.authCode : '';
}
export async function getAllMyEventsFromStorage() {
  return await ls.get('events') || [];
}
export const addAnonUserToEvent = async (name, phoneNumber, groupId,
  eventId) => new Promise((resolve, reject) => {
  axios
    .post(
      `${apiUrl}/groups/${groupId}/events/${eventId}/invites`,
      { name, phoneNumber },
    )
    .then((res) => {
      ls.set('name', name);
      ls.set('phoneNumber', phoneNumber);
      resolve(res.data);
    })
    .catch((err) => {
      reject(err);
    });
});
export const addUsersToEvent = async (details, groupId,
  eventId) => {
  const token = await getToken();
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${apiUrl}/me/groups/${groupId}/events/${eventId}/invites`,
        details,
        tokenConfig(token),
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
