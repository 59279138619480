
import React from 'react';

export const groupTypeArray = [
  'Sports Team',
  'Running Group',
  'Book Club',
  'Tutoring Group',
  'Parents Group',
  'Consert Goers',
  'Tournie Captains',
  'Other',
];

export const dummyGroup = {
  name: 'New Group',
  id: '0',
};


