import React from 'react';
import 'react-add-to-calendar/dist/react-add-to-calendar.css';
import '../../css/rsvpheader.css';
import moment from 'moment';
import { dateLocationEventHelper, rsvpHeaderCostDateEventHelper, CalendarDisplay } from './EventDetailHeaderAssets';
import { getResponseImage } from './EventAssets';

function EventDetailHeader(props) {
  const {
    event,
    confirmedCount,
    maybeCount,
    flipPressed,
    showDirectionPanel,
    showFlip,
    showResponse,
    responseStatus,
    click,
    eventCount,
  } = props;

  function groupInvitedHeader(nameOfGroup, status) {
    const monthStub = moment(event.eventDateTime).format('MMM');
    const dayStub = moment(event.eventDateTime).format('D');
    return (
      <div role="button" tabIndex="0" onClick={() => (click ? click() : null)} onKeyPress={() => (click ? click() : null)} className="rsvp-header--group-invite">
        <div className="rsvp-header--cost-date rsvp-group-item">
          <CalendarDisplay day={dayStub} month={monthStub} />
          <div className="rsvp-header--title">
            <div>{nameOfGroup}</div>
            <div className="confirmed-count">
              {(confirmedCount || maybeCount) && (
                `${confirmedCount} Confirmed | ${maybeCount} Maybe`
              )}
              {(eventCount > 0) && (
                ` | ${eventCount} Events`
              )}
            </div>
          </div>
          <div className="vertical-center-container">
            <div role="button" tabIndex="0" onClick={() => flipPressed()} onKeyPress={() => flipPressed()}>
              <img
                alt="rsvp"
                src={getResponseImage(status)}
                className="rsvp-list-response-img"
              />
            </div>
          </div>
        </div>
        {event.notes && showFlip && (
          <div className="notes">
            {event.notes}
          </div>
        )}
        <div className="solid" />
      </div>
    );
  }
  function eventInviteHeader() {
    const rsvpHeaderCostDateEvent = rsvpHeaderCostDateEventHelper(
      event,
      confirmedCount,
      flipPressed,
      showFlip,
      maybeCount,
      showResponse,
      responseStatus || (event.invite ? event.invite.responseStatus : ''),
    );
    // const rsvpHeaderTitleEvent = rsvpHeaderTitleEventHelper(nameOfEvent, howManyEvents);
    const dateLocationEvent = showDirectionPanel
      ? dateLocationEventHelper(event.eventDateTime, event.eventEndDateTime,
        event.location, event.locationUrl, event.lat, event.lon, event.description, event.notes) : '';
    return (
      <div role="button" tabIndex="0" onClick={() => (click ? click() : null)} onKeyPress={() => (click ? click() : null)} className="rsvp-header--event-invite">
        {rsvpHeaderCostDateEvent}
        <div className="solid" />
        {dateLocationEvent}
        <div className="solid" />
      </div>
    );
  }

  switch (event.eventType) {
    case 'GROUP-INVITE':
    case 'GROUP_JOIN':
      return groupInvitedHeader(event.group.name, responseStatus || (event.invite ? event.invite.responseStatus : ''));

    default:
      return eventInviteHeader();
  }
}

export default EventDetailHeader;
