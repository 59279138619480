import PropTypes from 'prop-types';

const EventGuestListDefaultProps = {
  invitedList: [null],
  declinedList: [null],
};
const EventGuestListPropTypes = {
  invitedList: PropTypes.arrayOf(PropTypes.object),
  declinedList: PropTypes.arrayOf(PropTypes.object),
};

const EventModalResponseDefaultProps = {
  userData: {
    name: 'unnamed',
  },
  eventType: 'EVENT',
};

const EventModalResponsePropTypes = {
  userData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    phoneNumber: PropTypes.string,
  }),
  visible: PropTypes.bool.isRequired,
  wasClicked: PropTypes.bool.isRequired,
  accept: PropTypes.func,
  decline: PropTypes.func,
  maybe: PropTypes.func,
  cancel: PropTypes.func.isRequired,
  eventType: PropTypes.string,
};

const EventDefaultProps = {
  user: {
    name: 'unnamed',
  },
  groupEvent: {
    location: 'virtual',
    name: 'unnamed',
    notes: '<String>',
    paymentAmount: null,
    paymentDateTime: null,
    creator: {
      name: 'unnamed',
    },
  },
};

const EventPropTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    isAdmin: PropTypes.bool,
    name: PropTypes.string,
    phoneNumber: PropTypes.string,
  }),
  groupEvent: PropTypes.shape({
    allDay: PropTypes.bool,
    eventDateTime: PropTypes.string,
    eventEndDateTime: PropTypes.string,
    eventType: PropTypes.string,
    groupId: PropTypes.string,
    id: PropTypes.string,
    location: PropTypes.string,
    locationUrl: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    notes: PropTypes.string,
    eventMediaUrl: PropTypes.string,
    eventCode: PropTypes.string,
    paymentAmount: PropTypes.number,
    lat: PropTypes.number,
    lon: PropTypes.number,
    paymentDateTime: PropTypes.string,
    sendInviteNow: PropTypes.bool,
    creator: PropTypes.shape({
      id: PropTypes.string,
      isAdmin: PropTypes.bool,
      name: PropTypes.string,
      phoneNumber: PropTypes.string,
    }),
  }),
  eventIndex: PropTypes.string,
  invited: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
};

const PayBubbleDefaultProps = {
  cost: null,
};
const PayBubblePropTypes = {
  cost: PropTypes.number,
};

const CalendarDisplayPropTypes = {
  day: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
};
const EventDetailProviderPropTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};
const EventDetailProviderDefaultProps = {
  location: {},
};
const EventModalPayDefaultProps = {
  visible: false,
  paymentAmount: null,
  slottedUser: {
    invitee: {
      membership: {
        isRegisteredUser: null,
        joinedDate: '',
        role: '',
        status: '',
      },
      id: '',
      name: '',
    },
    paymentStatus: '',
    responseStatus: '',
  },
};
const EventModalPayPropTypes = {
  visible: PropTypes.bool,
  paymentAmount: PropTypes.number,
  payWasClicked: PropTypes.bool.isRequired,
  paid: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  slottedUser: PropTypes.shape({
    invitee: PropTypes.shape({
      membership: PropTypes.shape({
        isRegisteredUser: PropTypes.bool,
        joinedDate: PropTypes.string,
        role: PropTypes.string,
        status: PropTypes.string,
      }),
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    paymentStatus: PropTypes.string,
    responseStatus: PropTypes.string,
  }),
};
export {
  EventGuestListDefaultProps,
  EventGuestListPropTypes,
  EventModalResponseDefaultProps,
  EventModalResponsePropTypes,
  EventDefaultProps,
  EventPropTypes,
  PayBubbleDefaultProps,
  PayBubblePropTypes,
  CalendarDisplayPropTypes,
  EventDetailProviderPropTypes,
  EventDetailProviderDefaultProps,
  EventModalPayDefaultProps,
  EventModalPayPropTypes,
};
