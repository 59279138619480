// imports
import React, { useState, useContext, useEffect } from 'react';
import '../../css/rsvp.css';
import { Redirect } from 'react-router-dom';
import '../../css/rsvpguestlist.css';
import ls from 'local-storage';
import { useSwipeable } from 'react-swipeable';
import EventDetailHeader from './EventDetailHeader';
import EventGuestList from './EventGuestList';
import EventModalResponse from './EventModalResponse';
import VideoControl from '../video/VideoControl';
import EventModalPay from './EventModalPay';
import { getResponseImage, listHelper } from './EventAssets';
import { EventDefaultProps, EventPropTypes } from './EventPropValidation';
import { respondedAsPaid, respondToEvent, searchLsForEventAuthCode } from '../../actions/rsvpActions';
import { AuthContext } from '../../contexts/AuthContext';
import MenuButton from '../buttons/MenuButton';
import AddButton from '../buttons/AddButton';

function Event(props) {
  const authContext = useContext(AuthContext);
  const { token } = authContext.auth;
  const { dispatch } = authContext;
  const [modalVisible, setVisible] = useState(false);
  const [payModalVisible, setPayModalVisible] = useState(false);
  const [flip, setFlip] = useState(false);
  const [payClicked, setPayWasClicked] = useState(false);
  const [redirectLink, setRedirect] = useState(null);
  const [attendingStatus] = useState();
  const {
    user, groupEvent, eventIndex, invited,
  } = props;
  const code = searchLsForEventAuthCode(groupEvent.id);
  const phoneNumber = ls.get('phoneNumber');
  const activeUserId = user ? user.id : '';
  const [modalWasClicked, setModalWasClicked] = useState(false);
  const {
    name: nameOfEvent,
    paymentAmount,
    eventType,
    eventMediaUrl,
    eventCode,
  } = groupEvent;
  const handleCancel = () => {
    setVisible(false);
    setPayModalVisible(false);
  };
  const userTouched = () => {
    setVisible(!modalVisible);
    setModalWasClicked(true);
  };
  const {
    declined, notDeclined, userPosition,
    accepted, maybe, invite,
  } = listHelper(invited, activeUserId || phoneNumber);
  const [responseStatus, setResponseStatus] = useState('');
  useEffect(() => {
    // action on update of invited
    setResponseStatus(invited && userPosition > -1 ? invited[userPosition].responseStatus : '');
  }, [invited, userPosition]);
  function getUserName() {
    if (invite) {
      return invite.invitee.name;
    }
    return '';
  }
  async function handleResponse(response) {
    const { groupId, id } = groupEvent;
    invited[userPosition].responseStatus = response;
    setResponseStatus(response);
    respondToEvent(groupId, id, activeUserId, response, await code, eventCode);
    if (paymentAmount && response === 'ACCEPTED') {
      setPayModalVisible(!payModalVisible);
      setPayWasClicked(true);
    }
    setVisible(!modalVisible);
  }
  const handleAccept = () => {
    handleResponse('ACCEPTED');
  };
  const handleDecline = () => {
    handleResponse('DECLINED');
  };
  const handleMaybe = () => {
    handleResponse('MAYBE');
  };
  const handleFlipPressed = () => {
    setFlip(!flip);
  };
  function getButtonClassName() {
    return '';
    // TODO: enable to color differently depending on response
    // if (invited && invited[userPosition]) {
    //   // eslint-disable-next-line default-case
    //   switch (invited[userPosition].responseStatus) {
    //     case 'ACCEPTED':
    //       return 'button-accepted-response';
    //     case 'DECLINED':
    //       return 'button-declined-response';
    //     case 'MAYBE':
    //       return 'button-maybe-response';
    //   }
    // }
    // return 'button-no-response';
  }
  const handlers = useSwipeable({
    onSwiped: (event) => {
      // eslint-disable-next-line no-console
      console.log('swiped', event);
      if ((event.dir === 'Left' || event.dir === 'Right') && eventMediaUrl) { handleFlipPressed(); }
    },
  });
  const handlePaid = () => {
    const { groupId, id } = groupEvent;
    respondedAsPaid(groupId, id, activeUserId, 'PAYMENT_SENT');
    setPayModalVisible(false);
  };
  const invitedList = notDeclined.map((data, index) => (
    <div key={data.invitee.id}>

      <div
        className="rsvp-user-cell"
        role="button"
      >
        <div className="rsvp-user-cell--number">{index + 1}</div>
        <div className="rsvp-user-name rsvp-user-cell-col">{data.invitee.name}</div>
        <div className="rsvp-user-response rsvp-user-cell-col">
          <img
            src={getResponseImage(attendingStatus || data.responseStatus)}
            alt={attendingStatus || data.responseStatus}
          />
        </div>

      </div>
      <div className="solid" />
    </div>
  ));
  const spanner = <span className="rsvp-declined--spanner">|</span>;
  const declinedList = declined.map(data => (
    <div
      role="button"
      className="rsvp-declined-name"
      key={data.invitee.id}
    >
      {data.invitee.name}
      {spanner}
    </div>
  ));
  const actions = [];
  actions.push({ title: token ? 'Logout' : 'Log In/Register', action: () => { dispatch({ type: 'CLEAR_AUTH' }); window.location = '/'; } });

  return (
    <div {...handlers} className="main-container">
      {redirectLink ? <Redirect to={redirectLink} /> : ''}
      <div className="rsvp-title">{nameOfEvent}</div>
      <div className={`flip-card rsvp-container ${flip ? 'flip-card-flip' : ''}`}>
        <div className="flip-card-inner">
          <div className="flip-card-front">
            <div className="front-card-inner">
              <EventDetailHeader
                event={groupEvent}
                eventIndex={eventIndex}
                confirmedCount={accepted.length}
                maybeCount={maybe.length}
                showFlip={!!eventMediaUrl}
                flipPressed={handleFlipPressed}
                showDirectionPanel="true"
              />
              <div className="rsvp-guest-list">
                <EventGuestList invitedList={invitedList} declinedList={declinedList} />
              </div>

            </div>
          </div>
          {eventMediaUrl
            ? (
              <div className="flip-card-back">
                <VideoControl
                  eventMediaUrl={eventMediaUrl}
                  flipPressed={handleFlipPressed}
                  responseStatus={responseStatus}
                />
              </div>
            ) : ''
          }
        </div>
      </div>
      <div className="control-panel">
        {/* <MenuButton click={() => setRedirect('/list')} /> */}
        <div
          role="button"
          onKeyPress={() => userTouched()}
          onClick={() => userTouched()}
          className={`respond-button respond-button-sticky respond-button-details ${getButtonClassName()}`}
          tabIndex="0"
        >
          CHANGE RSVP
        </div>
      </div>
      {/* <AddButton actions={actions}
      /> */}
      <div>
        <EventModalResponse
          visible={modalVisible}
          userName={getUserName()}
          wasClicked={modalWasClicked}
          accept={handleAccept}
          decline={handleDecline}
          maybe={handleMaybe}
          userData={user}
          cancel={handleCancel}
          eventType={eventType}
          slottedUser={invited ? invited[userPosition] : null}
          paymentAmount={paymentAmount}
        />
      </div>
      <div>
        <EventModalPay
          visible={payModalVisible}
          slottedUser={invited ? invited[userPosition] : null}
          paymentAmount={paymentAmount}
          cancel={handleCancel}
          payWasClicked={payClicked}
          paid={handlePaid}
        />
      </div>
    </div>
  );
}

export default Event;
Event.defaultProps = EventDefaultProps;
Event.propTypes = EventPropTypes;
