import React, { useState, useEffect } from 'react';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import '../../css/rsvpanondetailsmodal.css';
import { formatDialCodeNumber, saveCountrySelection, getCountryCode } from '../../actions/authActions';
// import MaskedInput from 'react-text-mask';
import {
  EventModalResponseDefaultProps,
  EventModalResponsePropTypes,
} from './EventPropValidation';
import BackButton from '../buttons/BackButton';

function EventAnonDetailsModal(props) {
  const {
    visible, accept, wasClicked, cancel, text, subText, defaultName, defaultPhone,
  } = props;
  const [error, setError] = useState('');
  const [email, setEmail] = useState(defaultPhone);
  const [name, setName] = useState(defaultName);
  const [defaultCountry, setDefaultCounty] = useState(getCountryCode());
  useEffect(() => {
    // action on update of invited
    setName(defaultName);
    setEmail(defaultPhone);
  }, [defaultName, defaultPhone]);
  const modalHeadline = <div className="yes-modal-header">{text}</div>;
  const modalDescription = (
    <div className="yes-modal-sub-header">
      {subText}
    </div>
  );
  function onChange(dialCode, number) {
    setEmail(formatDialCodeNumber(dialCode.dialCode, number));
  }
  function validate() {
    setError('');
    if (!email) {
      setError('Please enter a valid cell number');
      return false;
    }
    if (!name) {
      setError('Please enter your name');
      return false;
    }
    return true;
  }
  return (
    <div className={
      visible ? `yes-modal-${wasClicked}` : `yes-modal--hidden-${wasClicked}`
    }
    >
      <div className="modal-container">
        <BackButton click={() => cancel()} />
        {modalHeadline}
        {modalDescription}
        <div>
          <div>
            <input
              tabIndex={-1}
              onChange={e => setName(e.target.value)}
              className="rsvp-input"
              placeholder="Guestlist Name"
              type="text"
              defaultValue={defaultName || ''}
            />
          </div>
          <div>
            <IntlTelInput
              preferredCountries={['us', 'ie', 'gb']}
              autoComplete="true"
              inputClassName="rsvp-input"
              defaultCountry={defaultCountry}
              onSelectFlag={(y, z) => saveCountrySelection(z.iso2)}
              onPhoneNumberChange={(e, x, y) => onChange(y, x)}
            />
          </div>
          <div className="error-text">{error}</div>
          <div
            role="button"
            className="respond-button"
            tabIndex="-1"
            onKeyPress={() => {
              if (validate()) {
                accept(name, email);
              }
            }}
            onClick={() => {
              if (validate()) {
                accept(name, email);
              }
            }}
          >
            Submit
          </div>
        </div>

      </div>
    </div>
  );
}
export default EventAnonDetailsModal;
EventAnonDetailsModal.defaultProps = EventModalResponseDefaultProps;
EventAnonDetailsModal.propTypes = EventModalResponsePropTypes;
