import React, {
  useState, useContext, Fragment,
} from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import './auth.css';
// import MaskedInput from 'react-text-mask';
import {
  getCode, sendCode, getMe, formatDialCodeNumber, register, saveCountrySelection, getCountryCode,
} from '../../actions/authActions';
import { AuthContext } from '../../contexts/AuthContext';
import BackButton from '../buttons/BackButton';

export default function SignIn(props) {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [cognitoUser, setCognitoUser] = useState();
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const [defaultCountry, setDefaultCounty] = useState(getCountryCode());
  const [registerSelected, setRegisterSelected] = useState(false);
  const authContext = useContext(AuthContext);
  const { dispatch } = authContext;
  const { me } = authContext.auth;

  const submitEmail = async (evt) => {
    evt.preventDefault();
    try {
      const userResponse = await getCode(email);
      setCognitoUser(userResponse);
    } catch (err) {
      setError(err.message);
    }
  };

  const submitRegistration = async (evt) => {
    evt.preventDefault();
    try {
      const userResponse = await register(name, email);
      if (userResponse) {
        const newcode = await getCode(email);
        setCognitoUser(newcode);
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const submitCode = async (evt) => {
    evt.preventDefault();
    try {
      const cognitoAuth = await sendCode(cognitoUser, code);
      if (cognitoAuth.signInUserSession) {
        const idToken = cognitoAuth.signInUserSession.idToken.jwtToken;
        const refreshToken = cognitoAuth.signInUserSession.refreshToken.token;
        dispatch({ type: 'SET_TOKENS', data: { idToken, refreshToken } });
        const user = await getMe(idToken);
        dispatch({ type: 'SET_ME', data: { me: user } });
      } else {
        throw new Error('Invalid Credentials');
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const renderRedirect = () => {
    if (me) {
      // const queryParams = queryString.parse(props.location.search);
      // const { groupId, eventId } = queryParams;
      // const url = groupId && eventId ? `event?groupId=${groupId}&eventId=${eventId}` : 'event';
      return <Redirect to="list" />;
    }
    return <Fragment />;
  };
  function onChange(dialCode, number) {
    const phone = formatDialCodeNumber(dialCode.dialCode, number);
    console.log('change', { phone, code: dialCode.dialCode, number });
    setEmail(phone);
  }
  function formToFill() {
    if (cognitoUser) {
      return (
        <div>
          <form className="login-page--form" onSubmit={submitCode}>
            <input
              className="login-page--form-input"
              type="number"
              value={code}
              // autoFocus={true}
              placeholder="Code"
              onChange={e => setCode(e.target.value)}
            />
            <input className="login-page--form-submit" type="submit" value="Next" />
            <span className="error-text">{error}</span>
            <div>
              <span className="login-page--resend-code">Didn&apos;t receive?</span>
              <button type="submit" className="login-page--resend-code-button" onClick={submitEmail}>
                Try again
              </button>
            </div>
          </form>
        </div>
      );
    }
    if (registerSelected) {
      return (
        <div>
          <form className="login-page--form" onSubmit={submitRegistration}>
            <div>
              <input
                // ref={(input) => { nameInput = input; }}
                tabIndex={0}
                onChange={e => setName(e.target.value)}
                className="rsvp-input"
                placeholder="Name"
                type="text"
                defaultValue=""
              />
            </div>
            <IntlTelInput
              preferredCountries={['us', 'ie', 'gb']}
              autoComplete="true"
              defaultCountry={defaultCountry}
              onSelectFlag={(y, z) => saveCountrySelection(z.iso2)}
              inputClassName="login-page--form-input"
              onPhoneNumberChange={(e, x, y) => onChange(y, x)}
            />

            <span className="error-text">{error}</span>
            <input className="login-page--form-submit" type="submit" value="Register" />
          </form>
        </div>
      );
    }
    return (
      <form className="login-page--form" onSubmit={submitEmail}>
        <IntlTelInput
          preferredCountries={['us', 'ie', 'gb']}
          autoComplete="true"
          inputClassName="login-page--form-input"
          defaultCountry={defaultCountry}
          onPhoneNumberChange={(e, x, y) => onChange(y, x)}
          onSelectFlag={(y, z) => saveCountrySelection(z.iso2)}
        />
        <input className="login-page--form-submit" type="submit" value="Next" />
        <span className="error-text">{error}</span>
        <div tabIndex="-1" role="button" className="login-page--greeting" onKeyPress={() => setRegisterSelected(true)} onClick={() => setRegisterSelected(true)}>
          <h1>
            Not Registered?
            {' '}
            <div className="login-page--resend-code-button">Register Here</div>
          </h1>
        </div>
        <div role="button" tabIndex="0" className="login-page--greeting login-page--resend-code-button" onKeyPress={() => window.open('https://www.invites.ac', '_blank')} onClick={() => window.open('https://www.invites.ac', '_blank')}>Learn More</div>
      </form>
    );
  }
  function greeting() {
    if (cognitoUser) {
      return `Sending a text to ${email} Enter the code you recieve`;
    }
    if (registerSelected) {
      return 'Enter your registration details.';
    }
    return 'Enter your cell number so we can log you in.';
  }
  return (
    <div className="login-page">
      {(registerSelected || cognitoUser) && (
        <BackButton click={() => { setRegisterSelected(false); setCognitoUser(''); }} />
      )}
      <div className="login-page--greeting">
        <h1>{greeting()}</h1>
      </div>
      {formToFill()}
      {renderRedirect()}
    </div>
  );
}

SignIn.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

SignIn.defaultProps = {
  location: {},
};
