import { Auth } from 'aws-amplify';

export default (state, action) => {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        user: action.data,
      };
    case 'SET_TOKENS':
      return {
        ...state,
        token: action.data.idToken,
        refreshToken: action.data.refreshToken,
      };
    case 'SET_ME':
      return {
        ...state,
        me: action.data.me,
      };
    case 'CLEAR_AUTH':
      Auth.signOut();
      return {};
    default:
      return state;
  }
};
