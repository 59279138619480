import React from 'react';
import '../../css/rsvppaymodalcss.css';
import {
  EventModalPayDefaultProps,
  EventModalPayPropTypes,
} from './EventPropValidation';

// TODO: slottedUser is the user active

function EventModalPay(props) {
  const {
    visible, paymentAmount, cancel, payWasClicked, paid,
  } = props;
  const payHeader = `${'\ud83d\udcb0'}${paymentAmount} is owed`;
  return (
    <div className={`pay-modal-${visible}-${payWasClicked}`}>
      <h1 className="pay-modal--header">{payHeader}</h1>
      <a
        href="https://venmo.com/"
        rel="noopener noreferrer"
        target="_blank"
        className="pay-modal--venmo-link"
      >
        <button type="button" className="pay-modal--button pay-modal--venmo">
          pay with
          <img
            className="pay-modal--venmo-icon"
            src={`${process.env.PUBLIC_URL}/assets/venmoIco/venmoIco@3x.png`}
            alt="maybe"
          />
        </button>
      </a>
      <button onClick={paid} type="button" className="pay-modal--button pay-modal--paid">
        I paid already
      </button>
      <span
        className="pay-modal--cancel"
        tabIndex={-1}
        role="button"
        onKeyDown={cancel}
        onClick={cancel}
      >
        cancel
      </span>
    </div>
  );
}

export default EventModalPay;
EventModalPay.defaultProps = EventModalPayDefaultProps;
EventModalPay.propTypes = EventModalPayPropTypes;
