import React from 'react';
import './backbutton.css';
import PropTypes from 'prop-types';

function BackButton(props) {
  const { click } = props;
  return (
    <div
      role="button"
      onKeyPress={click}
      onClick={click}
      tabIndex={0}
    >
      <img
        alt="back"
        src={require('../../images/rsvp/back-ico.svg')}
        className="back-ico"
      />
    </div>
  );
}

export default BackButton;
BackButton.defaultProps = {
  click: null,
};
BackButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  click: PropTypes.func,
};
