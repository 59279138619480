import React from 'react';
import 'react-html5video/dist/styles.css';
import './VideoControlAssets/videocontrol.css';
import { DefaultPlayer as Video } from 'react-html5video';
import PropTypes from 'prop-types';
import { getResponseImage } from '../Events/EventAssets';

function VideoControl(props) {
  const {
    eventMediaUrl, flipPressed, responseStatus,
  } = props;
  function pauseVideo() {
    // eslint-disable-next-line no-console
    console.log('pausing video');
    const video = document.querySelector('video');
    video.pause();
  }
  let flipButton = '';
  if (flipPressed) {
    flipButton = (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
      <img
        alt={responseStatus}
        onKeyPress={() => {
          pauseVideo();
          flipPressed();
        }}
        onClick={() => {
          pauseVideo();
          flipPressed();
        }}
        src={getResponseImage(responseStatus)}
        className="video-flip-button"
      />
    );
  }

  return (
    <div className="video-container">
      <Video
        className="video-element"
        autoPlay
        controls={['Volume']}
        playsInline
        muted
        onLoadedData={() => {
          // load the video and pause
          // fixes iOS first image issue
          // eslint-disable-next-line no-console
          console.log('loaded data');
          const video = document.querySelector('video');
          video.pause();
          video.muted = false;
        }}
      >
        <source src={eventMediaUrl} />
        Your browser does not support the video tag.
      </Video>
      {/* <VideoPlayer className="video-element" {...videoJsOptions} /> */}
      {flipButton}
    </div>
  );
}

export default VideoControl;
VideoControl.defaultProps = {
  eventMediaUrl: '',
  flipPressed: null,
  responseStatus: '',
};
VideoControl.propTypes = {
  eventMediaUrl: PropTypes.string,
  flipPressed: PropTypes.func,
  responseStatus: PropTypes.string,
};
