/* eslint-disable linebreak-style */
/* eslint-disable prefer-destructuring */
import React, {
  useContext, Fragment, useEffect,
} from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import {
  EventDetailProviderPropTypes,
  EventDetailProviderDefaultProps,
} from '../Events/EventPropValidation';
import GroupCreate from './GroupCreate';

// TODO: Clean up useEffect log out issues

function GroupCreateProvider() {
  const authContext = useContext(AuthContext);
  const { token } = authContext.auth;

  useEffect(() => {

  }, [token]);


  return (
    <Fragment>
      {/* {eventHeader} */}
      <GroupCreate />
    </Fragment>
  );
}

export default GroupCreateProvider;

GroupCreateProvider.propTypes = EventDetailProviderPropTypes;

GroupCreateProvider.defaultProps = EventDetailProviderDefaultProps;
