import _axios from 'axios';
import { Auth } from 'aws-amplify';

let isAlreadyFetchingAccessToken = false;
let subscribers = [];

function onAccessTokenFetched(accessToken) {
  subscribers.forEach(callback => callback(accessToken));
  subscribers = [];
}

function addSubscriber(callback) {
  subscribers.push(callback);
}

async function resetTokenAndReattemptRequest(error) {
  try {
    const { response: errorResponse } = error;

    // const state = store.getState();
    // const resetToken = state.auth.refreshToken;

    const localData = localStorage.getItem('auth');
    const localAuth = JSON.parse(localData);
    const resetToken = localAuth.refreshToken;

    if (!resetToken) {
      return Promise.reject(error);
    }

    const retryOriginalRequest = new Promise((resolve) => {
      addSubscriber((accessToken) => {
        errorResponse.config.headers.Authorization = `Bearer ${accessToken}`;
        resolve(_axios(errorResponse.config));
      });
    });

    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true;

      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();

      cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
        if (err) {
          return Promise.reject(error);
        }

        const { idToken, refreshToken } = session;

        const { me } = localAuth;

        const auth = {
          token: idToken.jwtToken,
          refreshToken: refreshToken.token,
          me,
        };
        localStorage.setItem('auth', JSON.stringify(auth));
        isAlreadyFetchingAccessToken = false;
        return onAccessTokenFetched(auth.token);
      });
    }
    return retryOriginalRequest;
  } catch (err) {
    return Promise.reject(err);
  }
}

const axios = _axios.create({});

axios.interceptors.response.use(
  response => response,
  (error) => {
    const errorResponse = error.response;
    if (errorResponse && errorResponse.status === 401) {
      return resetTokenAndReattemptRequest(error);
    }

    return Promise.reject(error);
  },
);

export default axios;
