import React from 'react';
import AddToCalendar from 'react-add-to-calendar';
import moment from 'moment';
import {
  PayBubbleDefaultProps,
  PayBubblePropTypes,
  CalendarDisplayPropTypes,
} from './EventPropValidation';
import { getResponseImage } from './EventAssets';

const messageBubble = (
  <img alt="chat-bubble" src={`${process.env.PUBLIC_URL}/assets/chatIco/chatIco@3x.png`} />
);

function PayBubble(props) {
  const { cost } = props;
  const dollarString = (
    <span className="paybubble-dollar">
      $
      {cost}
    </span>
  );
  const costName = <span className="paybubble-cost">Cost</span>;
  return (
    <div className="paybubble">
      {dollarString}
      {costName}
    </div>
  );
}

function dateStringHelperNoYear(dateOfEvent) {
  const compareDate = moment(new Date()).format('MM-DD-YYYY');
  if (compareDate === moment(dateOfEvent).format('MM-DD-YYYY')) {
    return `Today, ${moment(dateOfEvent).format('h:mm A')}`;
  }
  return moment(dateOfEvent).format('MMM Do');
}
function rsvpGroupInviteHeader(dateOfEvent, eventIndex) {
  const confirmBy = (
    <span className="rsvp-header--group-invite-confirm-by-text">
      Confirm By
      {dateStringHelperNoYear(dateOfEvent)}
    </span>
  );
  return (
    <div className="rsvp-header--group-invite-confirm-by">
      {confirmBy}
      <span className="rsvp_header--group-number">
        (
        {eventIndex}
        )
      </span>
    </div>
  );
}

function rsvpGroupEventDetailHeaderInfo(paymentAmount) {
  return (
    <div className="rsvp-header--group-invite-cost">
      <span className="rsvp-header--group-invite-cost-amount">
        $
        {paymentAmount}
      </span>
      <span className="rsvp-header--group-invite-cost-register">Register Online</span>
      <span className="rsvp-header--group-invite-cost-bubble">{messageBubble}</span>
    </div>
  );
}


export function CalendarDisplay(props) {
  const { day, month } = props;
  const shownMonth = month.slice(0, 3);
  const monthSpan = <span className="calendar-month">{shownMonth}</span>;
  const daySpan = <span className="calendar-day">{day}</span>;
  return (
    <div className="calendar">
      {monthSpan}
      {daySpan}
    </div>
  );
}
function rsvpHeaderCostDateEventHelper(event, confirmedCount, flipPressed,
  showFlip, maybeCount, showResponse, response) {
  const monthStub = moment(event.eventDateTime).format('MMM');
  const dayStub = moment(event.eventDateTime).format('D');
  return (
    <div>
      <div className="rsvp-header--cost-date rsvp-event-item">
        {/* <PayBubble cost={paymentAmount} /> */}
        <CalendarDisplay day={dayStub} month={monthStub} />
        <div className="rsvp-header--title">
          <div>{event.description}</div>
          <div className="confirmed-count">
            {confirmedCount && maybeCount ? (`${confirmedCount} Confirmed | ${maybeCount} Maybe`) : event.group && (event.group.name)}
          </div>
          {/* <span className="rsvp-list--mesagge-bubble">{messageBubble}</span> */}
        </div>
        <div>
          {showFlip && (
            <div role="button" tabIndex="0" onClick={() => flipPressed()} onKeyPress={() => flipPressed()}>
              <img
                alt="flip"
                src={require('../../images/rsvp/play-button-mini.svg')}
                className="Play-Button---mini"
              />
            </div>
          )}
          {showResponse && (
            <div role="button" tabIndex="0" onClick={() => flipPressed()} onKeyPress={() => flipPressed()}>
              <img
                alt="rsvp"
                src={getResponseImage(response)}
                className="rsvp-list-response-img"
              />
            </div>
          )}
        </div>
      </div>
      {event.notes && showFlip && (
        <div className="notes">
          {event.notes}
        </div>
      )}
    </div>
  );
}
function dateLocationEventHelper(datestring, eventEndDateTime, location, locationUrl,
  lat, lon, title, description) {
  const destination = lat && lon ? `${lat},${lon}` : location;
  const direction = locationUrl || `https://www.google.com/maps?q=${destination}`;
  const fromTime = moment(datestring).format('ddd Do @ h:mmA');
  // const toTime = moment(eventEndDateTime).format('h:mmA');
  const event = {
    title,
    description,
    location,
    locationUrl,
    startTime: datestring,
    endTime: eventEndDateTime,
  };
  return (
    <div className="rsvp-header--date-location">
      <div className="rsvp-header--calender-container">
        <img alt="location" className="map-ico" src={require('../../images/rsvp/map-ico.svg')} />
        <span className="sub-header-text">
          {' '}
          {location}
        </span>
        <div>
          <a className="get-directions" rel="noreferrer noopener" target="_blank" href={direction}>Get Directions →</a>
        </div>
      </div>
      <div>
        <img alt="calendar" className="calendar-ico" src={require('../../images/rsvp/calendar-ico.svg')} />
        <span className="sub-header-text">
          {' '}
          {fromTime}
        </span>
        <div>
          <AddToCalendar event={event} buttonLabel="Add to Calendar" />
          {/* <a className="get-directions" rel="noreferrer noopener"
          target="_blank" href={direction}>Add to Calendar→</a> */}
        </div>
      </div>
    </div>
  );
}


export { dateLocationEventHelper, rsvpHeaderCostDateEventHelper };

PayBubble.defaultProps = PayBubbleDefaultProps;
PayBubble.propTypes = PayBubblePropTypes;
CalendarDisplay.propTypes = CalendarDisplayPropTypes;
