import React from 'react';
import {
  EventGuestListDefaultProps,
  EventGuestListPropTypes,
} from './EventPropValidation';

function EventGuestList(props) {
  const { invitedList, declinedList } = props;
  return (
    <div className="rsvp-user-list">
      {invitedList}
      {declinedList.length > 0 && (
        <div className="rsvp-declined">
          <h5>DECLINED</h5>
          <div className="rsvp-declined-list">{declinedList}</div>
        </div>
      )}

    </div>
  );
}

export default EventGuestList;

EventGuestList.defaultProps = EventGuestListDefaultProps;
EventGuestList.propTypes = EventGuestListPropTypes;
