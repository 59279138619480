// imports
import React, { useState, useContext } from 'react';
import Loader from 'react-loader-spinner';
import '../../css/rsvp.css';
import { AuthContext } from '../../contexts/AuthContext';
import '../../css/rsvpguestlist.css';
import EventModalResponse from './EventModalResponse';
import VideoControl from '../video/VideoControl';
import EventAnonDetailsModal from './EventAnonDetailsModal';
import { EventDefaultProps, EventPropTypes } from './EventPropValidation';
import { addAnonUserToEvent, respondToAnonEvent, respondToEvent } from '../../actions/rsvpActions';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';


function EventDetailVideo(props) {
  const authContext = useContext(AuthContext);
  const { token, me } = authContext.auth;
  const [modalVisible, setVisible] = useState(false);
  const [modalYesVisible, setYesVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [redirectLink, setRedirect] = useState(null);
  const [modalText, setModalText] = useState('');
  const [modalSubText, setModalSubText] = useState('');
  const [modalAction, setModalAction] = useState('ACCEPTED');
  const [modalWasClicked, setModalWasClicked] = useState(false);
  const [modalYesWasClicked, setModalYesWasClicked] = useState(false);
  const {
    user, groupEvent, defaultName, defaultPhone, responded,
  } = props;
  const {
    name: eventName,
    eventMediaUrl,
    paymentAmount,
    eventType,
    eventCode,
  } = groupEvent;

  const handleCancel = () => {
    setVisible(false);
  };
  const handleModalCancel = () => {
    // eslint-disable-next-line no-console
    console.log('modal cancel');
    setYesVisible(false);
  };
  const userTouched = () => {
    setVisible(!modalVisible);
    setModalWasClicked(true);
  };

  async function handleResponse(response) {
    setVisible(!modalVisible);

    if (token) {
      setLoading(true);
      const respond = await respondToEvent(groupEvent.groupId, groupEvent.id, me.id, response, '');
      if (respond) {
        responded(true);
      }
      setLoading(false);
      return;
    }
    // eslint-disable-next-line no-console
    console.log(response);
    if (response === 'ACCEPTED') {
      setModalText('You’re in! 🥳');
      setModalSubText('Excellent. Please confirm your name for the guestlist and your cell for if anything changes.');
    } else if (response === 'DECLINED') {
      setModalText('Aw, too bad. 😢');
      setModalSubText('No worries. Next time perhaps. If you confirm your name and cell, we can take you off the list.');
    } else {
      setModalText('So, maybe? 🤷‍♀️');
      setModalSubText('That’s cool. Let us know your name for the guestlist and your cell for if anything changes.');
    }
    setModalAction(response);
    setYesVisible(true);
    setModalYesWasClicked(true);
  }
  const handleAccept = () => {
    handleResponse('ACCEPTED');
  };
  const handleModalResponse = async (myname, phone) => {
    setLoading(true);
    const { groupId, id } = groupEvent;
    const strippedMobile = phone;
    // eslint-disable-next-line no-console
    console.log(`${myname} ${strippedMobile} has accepted`);
    const code = await addAnonUserToEvent(myname, strippedMobile, groupId, id, eventCode);
    if (!code.error) {
      await respondToAnonEvent(code, modalAction, id, eventCode);

      setLoading(false);
      responded(true);
      // redirect to details page
      // setRedirect(`/${groupEvent.eventCode}/details`);
    }
    setLoading(false);
  };
  const handleDecline = () => {
    handleResponse('DECLINED');
  };
  const handleMaybe = () => {
    handleResponse('MAYBE');
  };
  return (
    <div className="main-container">
      {/* {redirectLink ? <Redirect to={redirectLink} /> : '' } */}
      <Loader
        type="ThreeDots"
        visible={loading}
        className="loader"
        color="#2a3164"
        height={100}
        width={100}
      />
      <div className="video-header">{eventName}</div>
      <div className="rsvp-container">

        <VideoControl
          autoPlay="false"
          eventMediaUrl={eventMediaUrl}
        />
      </div>
      <div
        role="button"
        tabIndex={-1}
        className="respond-button respond-button-sticky respond-video-button"
        onKeyPress={() => userTouched()}
        onClick={() => userTouched()}
      >
        RSVP NOW
      </div>
      <EventModalResponse
        visible={modalVisible}
        wasClicked={modalWasClicked}
        accept={handleAccept}
        decline={handleDecline}
        maybe={handleMaybe}
        userData={user}
        cancel={handleCancel}
        userName={defaultName}
        eventType={eventType}
        slottedUser={null}
        paymentAmount={paymentAmount}
      />
      <EventAnonDetailsModal
        visible={modalYesVisible}
        accept={handleModalResponse}
        wasClicked={modalYesWasClicked}
        cancel={handleModalCancel}
        defaultName={defaultName}
        defaultPhone={defaultPhone}
        text={modalText}
        subText={modalSubText}
        response={modalAction}
      />
    </div>
  );
}

export default EventDetailVideo;
EventDetailVideo.defaultProps = EventDefaultProps;
EventDetailVideo.propTypes = EventPropTypes;
