// imports
import React, { useState } from 'react';
import '../../css/rsvp.css';
import EventModalResponse from './EventModalResponse';
import { EventDefaultProps, EventPropTypes } from './EventPropValidation';
import { respondToAnonEvent } from '../../actions/rsvpActions';


function EventAnon(props) {
  const [modalVisible, setVisible] = useState(true);
  const [modalWasClicked] = useState(true);
  const [showFinish, setShowFinish] = useState(false);

  const handleCancel = () => {
    // setVisible(false);
  };
  // const { declined, notDeclined, userPosition } = listHelper(invited, activeUserId);
  async function handleResponse(response) {
    try {
      await respondToAnonEvent(props.match.params.inviteCode, response);
      setVisible(!modalVisible);
      setShowFinish(true);
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert(error);
    }
  }
  const handleAccept = () => {
    handleResponse('ACCEPTED');
  };
  const handleDecline = () => {
    handleResponse('DECLINED');
  };
  const handleMaybe = () => {
    handleResponse('MAYBE');
  };


  return (
    <div className="rsvp-container">
      <EventModalResponse
        visible={modalVisible}
        wasClicked={modalWasClicked}
        accept={handleAccept}
        decline={handleDecline}
        maybe={handleMaybe}
        cancel={handleCancel}
        slottedUser={1}
      />
      <div style={showFinish ? {} : { display: 'none' }}>Thank You</div>
    </div>
  );
}

export default EventAnon;
EventAnon.defaultProps = EventDefaultProps;
EventAnon.propTypes = EventPropTypes;
