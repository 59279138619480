import React from 'react';
import '../../css/rsvpmodal.css';
import {
  EventModalResponseDefaultProps,
  EventModalResponsePropTypes,
} from './EventPropValidation';

function EventModalResponse(props) {
  const {
    visible, wasClicked, accept, decline, maybe, cancel, userName,
  } = props;
  const optInHeader = userName ? `${userName}, you in?` : 'So, are you in?';
  const modalHeadline = <div className="attending-modal-header">{optInHeader}</div>;
  return (
    <div>
      {visible
&& <div id="overlay" />
      }

      <div
        className={
        visible ? `attending-modal-${wasClicked}` : `attending-modal--hidden-${wasClicked}`
      }
      >
        {modalHeadline}
        {/* <span className="let-us-know">Let us know if you can make it?</span> */}
        <button className="attending-modal-response-button" type="button" onClick={accept}>
          <img
            className="attending-modal-response-button--icon"
            src={require('../../images/rsvp/active.svg')}
            alt="accept"
          />
          <p className="attending-modal-response-button--text">Yes, let&apos;s do it</p>
        </button>
        <button className="attending-modal-response-button" type="button" onClick={decline}>
          <img
            className="attending-modal-response-button--icon"
            src={require('../../images/rsvp/no.svg')}
            alt="decline"
          />
          <p className="attending-modal-response-button--text">No, can&apos;t make it</p>
        </button>
        <button className="attending-modal-response-button" type="button" onClick={maybe}>
          <img
            className="attending-modal-response-button--icon"
            src={require('../../images/rsvp/maybe-ico-grayed.svg')}
            alt="maybe"
          />
          <p className="attending-modal-response-button--text">Ugh, Maybe?</p>
        </button>
        <span
          className="attending-modal-cancel"
          tabIndex={-1}
          role="button"
          onKeyDown={cancel}
          onClick={cancel}
        >
          Cancel
        </span>
      </div>
    </div>
  );
}

export default EventModalResponse;
EventModalResponse.defaultProps = EventModalResponseDefaultProps;
EventModalResponse.propTypes = EventModalResponsePropTypes;
