// imports
import React, { useState, useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import '../../css/rsvp.css';
import '../../css/rsvpguestlist.css';
import ls from 'local-storage';
import { useSwipeable } from 'react-swipeable';
import GroupHeader from './GroupHeader';
import EventGuestList from '../Events/EventGuestList';
import EventModalResponse from '../Events/EventModalResponse';
import { getResponseImage, listHelper } from '../Events/EventAssets';
import { EventDefaultProps, EventPropTypes } from '../Events/EventPropValidation';
import { respondToEvent } from '../../actions/rsvpActions';
import { AuthContext } from '../../contexts/AuthContext';
import EventDetailHeader from '../Events/EventDetailHeader';
import EventAnonDetailsModal from '../Events/EventAnonDetailsModal';
import AddButton from '../buttons/AddButton';
import MenuButton from '../buttons/MenuButton';

function GroupDetail(props) {
  const authContext = useContext(AuthContext);
  const { token, me, dispatch } = authContext.auth;
  const [flip, setFlip] = useState(false);
  const [groupName, setGroupName] = useState('');
  const [remindLink, setRemindLink] = useState('sms:');
  const [invitees, setInvitees] = useState([]);
  const [attendingStatus] = useState();
  const [redirectLink, setRedirect] = useState(null);
  const [eventCount, setEventCount] = useState(null);
  const [modalVisible, setVisible] = useState(false);
  const [modalYesVisible, setYesVisible] = useState(false);
  const [modalAction, setModalAction] = useState('ACCEPTED');
  const [modalWasClicked, setModalWasClicked] = useState(false);
  const [modalYesWasClicked, setModalYesWasClicked] = useState(false);
  const {
    group, events, joinEvent, members, addToGroup,
  } = props;
  const phoneNumber = ls.get('phoneNumber');
  const activeUserId = me ? me.id : '';
  const handleCancel = () => {
    setVisible(false);
  };
  const userTouched = () => {
    setVisible(!modalVisible);
    setModalWasClicked(true);
  };
  const {
    declined, notDeclined, userPosition,
    accepted, maybe, invite,
  } = joinEvent ? listHelper(members, activeUserId || phoneNumber) : {
    declined: [],
    notDeclined: [],
    accepted: [],
    maybe: [],
  };
  const handleModalResponse = async (myname, phone) => {
    await addToGroup(myname, phone);
    setYesVisible(false);
    setModalYesWasClicked(false);
  };
  const handleModalCancel = () => {
    // eslint-disable-next-line no-console
    console.log('modal cancel');
    setYesVisible(false);
  };
  const [responseStatus, setResponseStatus] = useState('');
  useEffect(() => {
    if (group) {
      setGroupName(group.name);
      setInvitees(members);
      const link = members.map(member => member.invitee.phoneNumber).join(';');
      setRemindLink(`sms://open?addresses=${link}&body=Reminder to join ${group.name}`);
      // action on update of invited
      setResponseStatus(members && userPosition > -1 ? members[userPosition].responseStatus : '');
      setEventCount(events.length);
    }
  }, [joinEvent, userPosition]);
  function getUserName() {
    if (invite) {
      return invite.invitee.name;
    }
    return '';
  }
  function handleResponse(response) {
    const { groupId, id } = joinEvent;
    invitees[userPosition].responseStatus = response;
    setResponseStatus(response);
    respondToEvent(groupId, id, activeUserId, response);
    setVisible(!modalVisible);
  }
  const handleAccept = () => {
    handleResponse('ACCEPTED');
  };
  const handleDecline = () => {
    handleResponse('DECLINED');
  };
  const handleMaybe = () => {
    handleResponse('MAYBE');
  };
  const handleFlipPressed = () => {
    setFlip(!flip);
  };
  const handlers = useSwipeable({
    onSwiped: (event) => {
      // eslint-disable-next-line no-console
      console.log('swiped', event);
      if ((event.dir === 'Left' || event.dir === 'Right')) { handleFlipPressed(); }
    },
  });
  const invitedList = notDeclined.map((data, index) => (
    <div key={data.invitee.id}>

      <div
        className="rsvp-user-cell"
        role="button"
      >
        <div className="rsvp-user-cell--number">{index + 1}</div>
        <div className="rsvp-user-name rsvp-user-cell-col">{data.invitee.name}</div>
        <div className="rsvp-user-response rsvp-user-cell-col">
          <img
            src={getResponseImage(attendingStatus || data.responseStatus)}
            alt={attendingStatus || data.responseStatus}
          />
        </div>

      </div>
      <div className="solid" />
    </div>
  ));
  const spanner = <span className="rsvp-declined--spanner">|</span>;
  const declinedList = declined.map(data => (
    <div
      role="button"
      className="rsvp-declined-name"
      key={data.invitee.id}
    >
      {data.invitee.name}
      {spanner}
    </div>
  ));
  function navigateToEvent(event) {
    // navigate to the event
    if (event.eventCode) {
      setRedirect(`/${event.eventCode}`);
    } else {
      // group invite
      setRedirect(`/groups/${event.groupId}/events/${event.id}`);
    }
  }
  function createEvent() {

  }

  const actions = [{
    title: 'Add New Member',
    action: () => {
      setYesVisible(true);
      setModalYesWasClicked(true);
    },
  }, { title: 'Change Membership Status', action: () => userTouched() },
  { title: 'Create New Event', action: () => createEvent() },
  {
    title: 'Send Reminders', action: () => window.open(remindLink, '_blank'),
  }];
  actions.push({ title: token ? 'Logout' : 'Log In/Register', action: () => { dispatch({ type: 'CLEAR_AUTH' }); window.location = '/'; } });

  return (
    <div {...handlers} className="main-container">
      {redirectLink ? <Redirect to={redirectLink} /> : ''}
      <MenuButton click={() => setRedirect('/list')} />
      <div className="rsvp-title">
        <div>
          {groupName}
        </div>
      </div>
      <div className={`flip-card rsvp-container group-container ${flip ? 'flip-card-flip' : ''}`}>
        <div className="flip-card-inner">
          <div className="flip-card-front">
            <div className="front-card-inner">
              {joinEvent && (
                // <GroupHeader
                //   group={group}
                //   eventCount={events.length}
                //   confirmedCount={accepted.length}
                //   maybeCount={maybe.length}
                //   showFlip
                //   flipPressed={handleFlipPressed}
                // />
                <EventDetailHeader
                  event={joinEvent}
                  confirmedCount={accepted.length}
                  maybeCount={maybe.length}
                  showFlip
                  flipPressed={() => userTouched()}
                  showDirectionPanel={false}
                  showResponse="true"
                  responseStatus={responseStatus}
                  eventCount={eventCount}
                />
              )}
              <div className="rsvp-guest-list">
                <EventGuestList invitedList={invitedList} declinedList={declinedList} />
              </div>

            </div>
          </div>

          {/* <div className="flip-card-back">
            {joinEvent && (
              <GroupHeader
                group={group}
                eventCount={events.length}
                confirmedCount={accepted.length}
                maybeCount={maybe.length}
                showFlip
                flipPressed={handleFlipPressed}
              />
            )}
            {events.map(event => (
              <EventDetailHeader
                key={event.id}
                event={event}
                confirmedCount=""
                maybeCount=""
                showFlip={false}
                flipPressed={() => navigateToEvent(event)}
                showDirectionPanel={false}
                showResponse="true"
              />
            ))}
            {/* <VideoControl
              eventMediaUrl={0}
              flipPressed={handleFlipPressed}
              responseStatus={responseStatus}
            /> */}
          {/* </div>  */}

        </div>
      </div>
      {/* <div
        role="button"
        className="respond-button respond-button-sticky"
        onKeyPress={() => userTouched()}
        onClick={() => userTouched()}
        tabIndex="0"
      >
        Change My Membership Status
      </div> */}
      <div>
        <EventModalResponse
          visible={modalVisible}
          userName={getUserName()}
          wasClicked={modalWasClicked}
          accept={handleAccept}
          decline={handleDecline}
          maybe={handleMaybe}
          userData={me}
          cancel={handleCancel}
          eventType="GROUP_JOIN"
          slottedUser={invitees ? invitees[userPosition] : null}
        />
      </div>
      <div>
        <EventAnonDetailsModal
          visible={modalYesVisible}
          accept={handleModalResponse}
          wasClicked={modalYesWasClicked}
          cancel={handleModalCancel}
          text={`Add member to ${groupName}`}
          response={modalAction}
        />
      </div>
      <AddButton actions={actions} />
    </div>
  );
}

export default GroupDetail;
GroupDetail.defaultProps = EventDefaultProps;
GroupDetail.propTypes = EventPropTypes;
