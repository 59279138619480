/* eslint-disable linebreak-style */
/* eslint-disable prefer-destructuring */
import React, {
  useContext, Fragment, useState, useEffect,
} from 'react';
// import { Redirect } from 'react-router-dom';
import ls from 'local-storage';
import { AuthContext } from '../../contexts/AuthContext';
import {
  getGroupEventByCode, searchLsForEventAuthCode, getGroupEvent, getEvents,
} from '../../actions/rsvpActions';
import { updateEventDetailHeader } from './EventAssets';
import EventDetailVideo from './EventDetailVideo';
import EventDetailContainer from './EventDetailContainer';
import {
  EventDetailProviderPropTypes,
  EventDetailProviderDefaultProps,
} from './EventPropValidation';

// TODO: Clean up useEffect log out issues

function EventDetailProvider(props) {
  const authContext = useContext(AuthContext);
  const { me, token } = authContext.auth;
  const [error, setError] = useState('');
  const [groupEvent, setGroupEvent] = useState(null);
  // const [redirectLink, setRedirect] = useState(null);
  const [responded, setResponded] = useState(false);
  const [checked, setChecked] = useState(false);
  const [eventHeader, setEventDetailHeader] = useState(null);
  const { location } = props;
  // eslint-disable-next-line react/destructuring-assignment
  const eventCode = props.match.params.eventCode;
  const { search } = location;
  const [defaultName, setdefaultName] = useState('');
  const [defaultPhone, setdefaultPhone] = useState('');
  useEffect(() => {
    async function fetchData() {
      try {
        if (!eventCode) {
          throw new Error('No event found');
        }
        const event = await getGroupEventByCode(eventCode);
        setGroupEvent(event);
        // see if this user has access to the event, if they do then send them to the details page
        // if this user is not logged in, see if they have a phoneNumber saved in storgae
        const phoneNumber = await ls.get('phoneNumber');
        let code = '';
        const name = await ls.get('name');
        setdefaultName(name);
        setdefaultPhone(phoneNumber);

        if (!token) {
          code = await searchLsForEventAuthCode(event.id);
          // eslint-disable-next-line no-console
          console.log('getting phone number from storage', phoneNumber);
        }
        if ((phoneNumber && code)) {
          setResponded(true);
        } else if (token) {
          // see if this user is part of this event already
          const events = await getEvents(token);
          if (events && events.find(t => t.eventCode === eventCode)) {
            setResponded(true);
          }
        }
        setEventDetailHeader(updateEventDetailHeader(event));
      } catch (err) {
        setError(err.message || 'Error!');
      } finally {
        setChecked(true);
      }
    }
    fetchData();
  }, [token, search, responded, eventCode]);

  return (
    <Fragment>
      {eventHeader}
      {error}
      {/* {redirectLink ? <Redirect to={redirectLink} /> : ''} */}
      {groupEvent && !responded && checked && (
        <EventDetailVideo
          user={me}
          defaultName={defaultName}
          defaultPhone={defaultPhone}
          groupEvent={groupEvent}
          responded={setResponded}
        />
      )}
      {groupEvent && responded ? (<EventDetailContainer eventCode={eventCode} />) : ''}
    </Fragment>
  );
}

export default EventDetailProvider;

EventDetailProvider.propTypes = EventDetailProviderPropTypes;

EventDetailProvider.defaultProps = EventDetailProviderDefaultProps;
