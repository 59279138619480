import moment from 'moment';
import { Helmet } from 'react-helmet';
import React from 'react';

function updateEventDetailHeader(event) {
  if (!event) {
    return null;
  }
  return (
    <Helmet>
      <title>{event.description}</title>
      <meta property="og:title" content={event.description} />
      <meta property="og:description" content={event.notes} />
      <meta property="og:url" content={`${window.location.protocol}//${window.location.host}/${event.eventCode}`} />
    </Helmet>
  );
}
function getResponseImage(status) {
  switch (status) {
    case 'ACCEPTED':
      return `${process.env.PUBLIC_URL}/assets/active/active@3x.png`;
    case 'MAYBE':
      return `${process.env.PUBLIC_URL}/assets/maybe/maybeIcosquare@3x.png`;
    case 'DECLINED':
      return `${process.env.PUBLIC_URL}/assets/no/no@3x.png`;
    default:
      return `${process.env.PUBLIC_URL}/assets/noresponse/noResponseIco@3x.png`;
  }
}
function dateStringHelper(dateOfEvent) {
  return moment(dateOfEvent).calendar(null, {
    sameDay: '[Today] h:mm A',
    nextDay: '[Tomorrow] h:mm A',
    nextWeek: 'dddd h:mm A',
    lastDay: '[Yesterday]',
    lastWeek: '[Last] dddd',
    sameElse: 'ddd MM/DD h:mm A',
  });
}
function listHelper(invited, activeUserId) {
  const declined = [];
  const notDeclined = [];
  const accepted = [];
  const maybe = [];
  let invite;
  let userPosition = -1;
  if (invited) {
    invited.forEach((element, i) => {
      if (element.invitee.id === activeUserId || element.invitee.phoneNumber === activeUserId) {
        userPosition = i;
        invite = element;
      }
      if (invited[i].responseStatus === 'DECLINED') {
        declined.push(element);
      } else {
        notDeclined.push(element);
        if (invited[i].responseStatus === 'ACCEPTED') {
          accepted.push(element);
        } else {
          maybe.push(element);
        }
      }
    });
  }
  return {
    declined,
    notDeclined,
    userPosition,
    accepted,
    maybe,
    invite,
  };
}
export {
  getResponseImage, dateStringHelper, listHelper, updateEventDetailHeader,
};
