/* eslint-disable linebreak-style */
/* eslint-disable prefer-destructuring */
import React, {
  useContext, Fragment, useState, useEffect,
} from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import {
  EventDetailProviderPropTypes,
  EventDetailProviderDefaultProps,
} from '../Events/EventPropValidation';
import {
  getGroup, getGroupEvents, getEventInvitees, addUsersToEvent,
} from '../../actions/rsvpActions';
import GroupDetail from './GroupDetail';

// TODO: Clean up useEffect log out issues

function GroupDetailProvider(props) {
  const authContext = useContext(AuthContext);
  const { token } = authContext.auth;
  const [error, setError] = useState('');
  const [group, setGroup] = useState(null);
  const [members, setMembers] = useState([]);
  const [groupJoinEvent, setGroupJoinEvent] = useState(null);
  const [groupEvents, setGroupEvents] = useState([]);

  // eslint-disable-next-line react/destructuring-assignment
  const groupId = props.match.params.groupId;

  useEffect(() => {
    async function fetchData() {
      try {
        if (!groupId || !token) {
          throw new Error('No group found');
        }
        // get the group details
        const [groupDetails, events] = await Promise.all([getGroup(token, groupId),
          getGroupEvents(groupId)]);

        if (!groupDetails) {
          throw new Error('No group found');
        }
        setGroup(groupDetails);
        setGroupEvents(events.filter(t => t.eventType !== 'GROUP_JOIN'));
        const join = events.find(t => t.eventType === 'GROUP_JOIN');
        setGroupJoinEvent(join);
        const invitees = await getEventInvitees(groupId, join.id);
        setMembers(invitees);
      } catch (err) {
        setError(err.message || 'Error!');
      }
    }
    fetchData();
  }, [token, groupId]);

  async function addUserToGroup(name, phoneNumber) {
    await addUsersToEvent(token, [{ name, phoneNumber }], groupId, groupJoinEvent.id);
    const invitees = await getEventInvitees(groupId, groupJoinEvent.id);
    setMembers(invitees);
  }
  return (
    <Fragment>
      {/* {eventHeader} */}
      {error}
      <GroupDetail
        addToGroup={addUserToGroup}
        group={group}
        events={groupEvents}
        members={members}
        joinEvent={groupJoinEvent}
      />
    </Fragment>
  );
}

export default GroupDetailProvider;

GroupDetailProvider.propTypes = EventDetailProviderPropTypes;

GroupDetailProvider.defaultProps = EventDetailProviderDefaultProps;
