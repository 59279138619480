/* eslint-disable linebreak-style */
/* eslint-disable prefer-destructuring */
import React, {
  useContext, Fragment, useState, useEffect,
} from 'react';
import ls from 'local-storage';
import { AuthContext } from '../../contexts/AuthContext';
import {
  getGroupEventByCode, getEventInvitees, getEventInviteesAnon, searchLsForEventAuthCode,
} from '../../actions/rsvpActions';
import {
  EventDetailProviderPropTypes,
  EventDetailProviderDefaultProps,
} from './EventPropValidation';
import EventDetail from './EventDetail';

// TODO: Clean up useEffect log out issues

function EventDetailContainer(props) {
  const authContext = useContext(AuthContext);
  const { me, token } = authContext.auth;
  const [error, setError] = useState('');
  const [groupEvent, setGroupEvent] = useState(null);
  const [groupInvitees, setinvitees] = useState(null);
  const { location } = props;
  const { search } = location;
  let phoneNumber = '';
  useEffect(() => {
    async function fetchData() {
      const eventCode = props.eventCode;
      try {
        if (!eventCode) {
          throw new Error('No event found');
        }
        // if this user is not logged in, see if they have a phoneNumber saved in storgae
        if (!token) {
          phoneNumber = await ls.get('phoneNumber');
          // eslint-disable-next-line no-console
          console.log('getting phone number from storage', phoneNumber);
        }
        if (!token && !phoneNumber) {
          throw new Error('Unauthorized');
        }
        const event = await getGroupEventByCode(eventCode);
        setGroupEvent(event);
        const tempCode = await searchLsForEventAuthCode(event.id);
        // eslint-disable-next-line no-console
        console.log(tempCode);
        const invitees = token ? await getEventInvitees(event.groupId, event.id)
          : await getEventInviteesAnon(tempCode, event.groupId, event.id);
        // get the invitees
        setinvitees(invitees);
      } catch (err) {
        setError(err.message || 'Error!');
        // redirect back to event page
        // eslint-disable-next-line no-console
        console.log('added redirect link.', err);
        // setRedirect(`/${eventCode}`);
      }
    }
    fetchData();
  }, [token, search]);

  return (
    <Fragment>
      {error}
      {/* {redirectLink ? <Redirect to={redirectLink} /> : ''} */}
      {groupEvent ? (
        <EventDetail
          user={me}
          invited={groupInvitees}
          groupEvent={groupEvent}
          eventIndex="0"
        />
      ) : ''}
    </Fragment>
  );
}

export default EventDetailContainer;

EventDetailContainer.propTypes = EventDetailProviderPropTypes;

EventDetailContainer.defaultProps = EventDetailProviderDefaultProps;
