import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';
import Amplify from 'aws-amplify';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { config } from './config/environments';

Amplify.configure({
  Auth: {
    region: config.region,
    userPoolId: config.userPoolId,
    userPoolWebClientId: config.userPoolWebClientId,
  },
});

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.register();
