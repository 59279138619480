import { Auth } from 'aws-amplify';
import ls from 'local-storage';
import axios from '../config/axios';
import { apiUrl } from '../config/environments';

export async function getToken(stopLogout) {
  // Async call to Cognito to check for token
  // This will be checked every time a protected route is loaded

  try {
    const session = await Auth.currentSession();
    return session.idToken.jwtToken;
  } catch (error) {
    if (!stopLogout) {
      await Auth.signOut();
      await ls.set('auth', '');
      window.location.reload();
    }
  }
  return '';
}
export const tokenConfig = (token) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
};
export const getMe = async () => {
  const token = await getToken();
  return new Promise(
    (resolve, reject) => {
      axios
        .get(`${apiUrl}/me`, tokenConfig(token))
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    },
  );
};

export const getCode = async (email) => {
  const cognitoUser = await Auth.signIn(email);
  return cognitoUser;
};
// async function setSession(session) {
//   const authContext = useContext(AuthContext);
//   const { dispatch } = authContext;
//   const idToken = session.idToken.jwtToken;
//   const refreshToken = session.refreshToken.token;
//   dispatch({ type: 'SET_TOKENS', data: { idToken, refreshToken } });
//   const newuser = await getMe(idToken);
//   dispatch({ type: 'SET_ME', data: { me: newuser } });
// }
// export const tryRefreshSession = async () => {
//   try {
//     const cognitoUser = await Auth.currentAuthenticatedUser();
//     const currentSession = await Auth.currentSession();
//     cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
//       console.log('session', err, session);
//       return session;
//       // do whatever you want to do now :)
//     });
//   } catch (e) {
//     console.log('Unable to refresh Token', e);
//   }
// };
export const register = async (name, phone) => Auth.signUp({
  username: phone,
  password: 'randompassword',
  attributes: {
    phone_number: phone, // optional
    name, // optional - E.164 number convention
    // other custom attributes
  },
  validationData: [], // optional
});

export function saveCountrySelection(countryCode) {
  // console.log(countryCode);
  ls.set('countryCode', countryCode);
}

export function getCountryCode() {
  return ls.get('countryCode') || 'us';
}

export function formatDialCodeNumber(dialCode, number) {
  return `+${dialCode}${number.replace(/\D+/g, '').replace(/^0+/, '').replace(/\+/g, '')}`;
}

export const sendCode = async (user, code) => {
  const cognitoAuth = await Auth.sendCustomChallengeAnswer(user, code);
  if (cognitoAuth.signInUserSession) {
    // setSession(cognitoAuth.signInUserSession);
  } else {
    throw new Error('Invalid Credentials');
  }
  return cognitoAuth;
};
